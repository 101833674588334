import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deleteImg from '../../../assets/icons/delete-files.svg';
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
import './styles.css'
import ImportIcon from '../../../assets/icons/icon-import.svg'
import { useMainContext } from "../../../contexts/MainContext";
import { isNull } from 'lodash';
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
import InputRadioList from '../../../components/atoms/InputRadioList';
import Dropdown from "../../../components/atoms/Dropdown";


const ModalImportFile = (props) => {
    let mainContext = useMainContext();
    const { showModal, setShow, setCancelAction, handlerAccept, cancel, success, size, optionsCommercialBusinessUses3, optionMarketingSchemes } = props
    const [name, setName] = useState(isNull)
    const [formData, setFormData] = useState({});
    let catalogs = useCatalogsContext();
    const [optionsCommercialBusiness, setOptionsCommercialBusiness] = useState([]);

    React.useEffect(() => {
        if (optionsCommercialBusinessUses3) {
            let options = catalogs.commercialBusiness.filter((item) => optionsCommercialBusinessUses3?.map((element) => element.commercialBusinessID).includes(item.commercialBusinessID));
            setOptionsCommercialBusiness(options);
        }
    }, [optionsCommercialBusinessUses3]);


    /**
        * Cierra el modal.
    */
    const handleClose = () => {
        setFormData({});
        setShow(false);
    }

    /**
        * Comfirmacion de borrado.
    */
    useEffect(() => {
        setFormData({});
    }, [showModal])

    /**
     * Envia los datos al padre.
     */
    const sendData = () => {
        if (formData.typeLifting && formData.comercialBusinessID)
            handlerAccept(formData);
        else
            mainContext.setMessageAlert("Selecciona un tipo de levantamiento y un giro comercial");
    }

    /**
     *  Cambio de datos en el formulario.
     * @param {*} nameProp 
     * @param {*} data 
     */
    const onFormDataChange = async (nameProp, data) => {
        mainContext.setIsDirty(true);
        setFormData({ ...formData, [nameProp]: data });
    }

    return (
        //TODO: Fix styles
        <section>
            <Modal size={"lg"} show={showModal} onHide={() => { handleClose() }} backdrop="static" centered >
                <Modal.Header className="no-border p-1" closeButton>
                    <Modal.Title className="pl-3"></Modal.Title>
                </Modal.Header>
                <Modal.Body className={"text-center"} >

                    <h4 className="stylesTilteCard">CREAR NUEVO LEVANTAMIENTO</h4>

                    <div className="row content-radio-select">
                        <p>Tipo de levantamiento:</p>
                        <p title='Estándar'>
                            <input name='newUprising' onChange={(e) => { return e.target.checked ? onFormDataChange('typeLifting', { name: 'Estándar', value: 0 }) : null }}
                                checked={formData.standard} type="radio" /> Estándar
                        </p>
                        <p title='Vendido'>
                            <input name='newUprising' onChange={(e) => { return e.target.checked ? onFormDataChange('typeLifting', { name: 'Vendido', value: 1 }) : null }}
                                checked={formData.sold} type="radio" disabled={optionMarketingSchemes != 1} /> Vendido
                        </p>
                        <p title='Rentado'>
                            <input name='newUprising' onChange={(e) => { return e.target.checked ? onFormDataChange('typeLifting', { name: 'Rentado', value: 2 }) : null }}
                                checked={formData.rented} type="radio" disabled={optionMarketingSchemes != 2} /> Rentado
                        </p>
                        <p title='Vendido y Rentado'>
                            <input name='newUprising' onChange={(e) => { return e.target.checked ? onFormDataChange('typeLifting', { name: 'Vendido y Rentado', value: 3 }) : null }}
                                checked={formData.sold_rented} type="radio" disabled={optionMarketingSchemes != 3} /> Vendido y Rentado
                        </p>
                    </div>
                    <div className="row content-radio-select">
                        <p>Giro del Levantamiento:</p>
                        <Dropdown value={formData.comercialBusinessID} options={optionsCommercialBusiness} onChange={(e) => { onFormDataChange("comercialBusinessID", e.value) }} optionValue="commercialBusinessID" optionLabel="description" className="w-100" />
                    </div>

                    <div
                        className="container d-flex justify-content-end mt-5">
                        <button className="btn btn-white " onClick={() => { handleClose() }}>Cancelar</button>

                        <button className="btn btn-primary ml-3" onClick={sendData}>crear</button>
                    </div>
                </Modal.Body>
            </Modal>
        </section >
    )
}
export default ModalImportFile