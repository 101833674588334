import { getRoles } from '@testing-library/react';
import React, { createContext, useContext, useState } from 'react';
export const CatalogsContext = createContext({});

export const CatalogsContextProvider = (props) => {
    const [developers, setDevelopers] = useState(null);
    const [countries, setCountries] = useState(null);
    //const [towers, setTowers] = useState(null);
    //const [complexUses, setComplexUses] = useState(null);
    //const [masterPlanUses, setMasterPlanUses] = useState(null);
    const [masterPlan, setMasterPlan] = useState(null);
    const [tradeStatus, setTradeStatus] = useState(null);
    const [workProgress, setWorkProgress] = useState(null);
    const [classifications, setClassifications] = useState(null);
    const [marketingSchemes, setMarketingSchemes] = useState(null);
    const [fileTypes, setFileTypes] = useState(null);
    const [services4S, setServices4S] = useState(null);
    const [cupboardTypes, setCupboardTypes] = useState(null);
    const [floorTypes, setFloorTypes] = useState(null);
    const [kitchenDistributions, setKitchenDistributions] = useState(null);
    const [laundryRooms, setLaundryRooms] = useState(null);
    const [modelServices, setModelServices] = useState(null);
    const [modelSpecials, setModelSpecials] = useState(null);
    const [modelTypes, setModelTypes] = useState(null);
    const [naturalVentilationSpaces, setNaturalVentilationSpaces] = useState(null);
    const [doubleHeightSpaces, setDoubleHeightSpaces] = useState(null);
    const [numberViews, setNumberViews] = useState(null);
    const [walkInRooms, setWalkInRooms] = useState(null);
    const [houseDistributions, setHouseDistributions] = useState(null);
    const [landDistributions, setLandDistributions] = useState(null);
    const [currencies, setCurrencies] = useState(null);
    const [areaLocations, setAreaLocations] = useState(null);
    const [floorTypeUses, setFloorTypeUses] = useState(null);
    const [models, setModels] = useState(null);
    const [saleStatus, setSaleStatus] = useState(null);
    const [wallFinish, setWallFinish] = useState(null);
    const [ceiling, setCeiling] = useState(null);
    const [lighting, setLighting] = useState(null);
    const [systemAC, setSystemAC] = useState(null);
    const [deckTileTypes, setDeckTileTypes] = useState(null);
    const [deckTileOrigins, setDeckTileOrigins] = useState(null);
    const [deckTileMeasures, setDeckTileMeasures] = useState(null);
    const [commercialBusiness, setCommercialBusiness] = useState(null);

    const [elevatorTypes, setElevatorTypes] = useState(null);
    const [buildingTypes, setBuildingTypes] = useState(null);
    const [facadeTypes, setFacadeTypes] = useState(null);
    const [interiorWallTypes, setInteriorWallTypes] = useState(null);
    const [dividenWallTypes, setDividenWallTypes] = useState(null);
    const [windowTypes, setWindowTypes] = useState(null);

    const [finishedTypes, setFinishedTypes] = useState(null);
    const [finishedFeatures, setFinishedFeatures] = useState(null);
    const [finishedTypesFeatures, setFinishedTypesFeatures] = useState(null);
    const [finishedDeliveryTypes, setFinishedDeliveryTypes] = useState(null);
    const [rentalStatus, setRentalStatus] = useState(null);
    const [subCategories, setSubcategories] = useState(null);
    const [roles, setRoles] = useState(null);
    const [generalCats, setGeneralCats] = useState(null);
    const [amenityTypes, setAmenityTypes] = useState(null);
    const [commercialCategories, setCommercialCategories] = useState(null);
    const [subCommercialCategories, setSubCommercialCategories] = useState(null);
    const [offices, setOffices] = useState(null);
    const [usoTorre, setUsoTorre] = useState([]);
    const [states, setStates] = useState(null);
    const [cities, setCities] = useState(null);
    const [marketers, setMarketers] = useState(null);
    const [parkingPaymentMethods, setParkingPaymentMethods] = useState([]);
    const [parkingFeeType, setParkingFeeType] = useState([]);

    const value = {
        houseDistributions,
        setHouseDistributions,
        landDistributions,
        setLandDistributions,
        floorTypes,
        setFloorTypes,
        kitchenDistributions,
        setKitchenDistributions,
        laundryRooms,
        setLaundryRooms,
        modelServices,
        setModelServices,
        modelSpecials,
        setModelSpecials,
        modelTypes,
        setModelTypes,
        naturalVentilationSpaces,
        setNaturalVentilationSpaces,
        doubleHeightSpaces,
        setDoubleHeightSpaces,
        numberViews,
        setNumberViews,
        walkInRooms,
        setWalkInRooms,
        developers,
        setDevelopers,
        countries,
        setCountries,
        //towers,
        //setTowers,
        //complexUses,
        //setComplexUses,
        masterPlan,
        setMasterPlan,
        //masterPlanUses,
        //setMasterPlanUses,
        tradeStatus,
        setTradeStatus,
        workProgress,
        setWorkProgress,
        classifications,
        setClassifications,
        marketingSchemes, setMarketingSchemes,
        fileTypes,
        setFileTypes,
        services4S,
        setServices4S,
        cupboardTypes,
        setCupboardTypes,
        currencies,
        setCurrencies,
        areaLocations,
        setAreaLocations,
        floorTypeUses,
        setFloorTypeUses,
        models,
        setModels,
        saleStatus,
        setSaleStatus,
        wallFinish,
        setWallFinish,
        ceiling, setCeiling,
        lighting, setLighting,
        systemAC, setSystemAC,
        deckTileTypes, setDeckTileTypes,
        deckTileOrigins, setDeckTileOrigins,
        deckTileMeasures, setDeckTileMeasures,
        elevatorTypes, setElevatorTypes,
        buildingTypes, setBuildingTypes,
        facadeTypes, setFacadeTypes,
        interiorWallTypes, setInteriorWallTypes,
        dividenWallTypes, setDividenWallTypes,
        windowTypes, setWindowTypes,
        finishedTypes,
        setFinishedTypes,
        finishedFeatures,
        setFinishedFeatures,
        finishedTypesFeatures,
        setFinishedTypesFeatures,
        finishedDeliveryTypes,
        setFinishedDeliveryTypes,
        commercialBusiness, setCommercialBusiness,
        rentalStatus, setRentalStatus,
        subCategories, setSubcategories,
        roles, setRoles,
        generalCats, setGeneralCats,
        amenityTypes, setAmenityTypes,
        commercialCategories, setCommercialCategories,
        subCommercialCategories, setSubCommercialCategories,
        offices, setOffices,
        usoTorre, setUsoTorre,
        states, setStates,
        cities, setCities,
        marketers, setMarketers,
        parkingPaymentMethods, setParkingPaymentMethods,
        parkingFeeType, setParkingFeeType
    }
    return <CatalogsContext.Provider value={value} {...props} />
}

export const useCatalogsContext = () => {
    const context = useContext(CatalogsContext);
    if (!context) {
        throw new Error("no existe el context")
    }
    return context;
}