import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup';
import NumberFormat from 'react-number-format';
import Row from 'react-bootstrap/Row'

import Alert from '../../../components/atoms2/Alert';
import { useMainContext } from "../../../contexts/MainContext";
import Dropdown from '../../../components/atoms/Dropdown';
// import { Dropdown } from 'primereact/dropdown';

const SalesAdditionals = ({ handlerFormData, isSubmitted, initData = {}, parkingPaymentMethods, parkingFeeType, parkingPlaza, setParkingPlaza }) => {
    let mainContext = useMainContext();

    const [formData, setFormData] = useState({ paymentMethods: [] });
    const [originalData, setOriginalData] = useState({});
    const [errorMessageWarehouse, setErrorMessageWarehouse] = useState(false);

    useEffect(() => {
        handlerFormData(formData);
    }, [formData])

    useEffect(() => {
        if (initData.phaseID)
            initForm();
    }, [initData])

    const initForm = () => {
        let newData = { ...initData };
        delete newData.exchangesRates;
        setFormData(newData);
        setOriginalData(newData);
    }

    const resetFields = async (switchField, value) => {
        let contextSales = { ...formData }
        switch (switchField) {
            case "parking":
                contextSales.additionalParkingSpaces = !formData.additionalParkingSpaces
                contextSales.numParkingSpaces = null;
                contextSales.parkingSpacesPrice = null;
                await setFormData(contextSales)
                break
            case "warehouse":
                contextSales.warehouse = !formData.warehouse
                contextSales.minWarehouse = null;
                contextSales.maxWarehouse = null;
                contextSales.priceM2Warehouse = null;
                await setFormData(contextSales)
                break;
            case "parkingPool":
                contextSales.poolParking = !formData.poolParking
                contextSales.maxAccess = null;
                contextSales.accessPrice = null;
                contextSales.parkingRelation = null;
                contextSales.asignedParkingLots = null;
                await setFormData(contextSales)
                break;
        }

    }

    const changeRateType = async (objetValue) => {
        let contextSales = { ...formData }
        contextSales.rateType = objetValue;
        if (objetValue?.id == 2) {
            setParkingPlaza(false);
        } else {
            setParkingPlaza(true);
        }

        await setFormData(contextSales);
    };

    const changePaymentMethods = (objetValue) => {
        let contextSales = { ...formData }
        const value = Number(objetValue.value);
        let newPaymentMethods;

        if (objetValue.checked) {
            newPaymentMethods = [...(formData?.paymentMethods || []), value];
        } else {
            newPaymentMethods = formData?.paymentMethods.filter(id => id !== value);
        }
        setFormData(prev => ({ ...prev, paymentMethods: newPaymentMethods }));
    };

    return (
        <section className="custom-card mt-4">
            <div className="custom-card-header px-3 d-flex">
                <h4 className="py-3">VENTAS ADICIONALES</h4>
            </div>
            <form className="custom-card-body px-3" autoComplete="off">
                <Form.Row>
                    <Col>
                        <Form.Group controlId="">
                            <Form.Label>Cajón adicional</Form.Label>
                            <Form.Check
                                onClick={(e) => {
                                    resetFields("parking", !formData.additionalParkingSpaces)
                                }}
                                defaultChecked={formData.additionalParkingSpaces}
                                type="switch"
                                label=""
                                id="aditional-parking-switch"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="validateNumParkinSpaces">
                            <Form.Label>Cajones a la venta</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>#</InputGroup.Text>
                                </InputGroup.Prepend>
                                <NumberFormat
                                    decimalScale={0}
                                    allowNegative={false}
                                    maxLength="9"
                                    className="black-border form-control"
                                    value={formData.numParkingSpaces || ""}
                                    thousandSeparator={true}
                                    disabled={!formData.additionalParkingSpaces}
                                    required={false}
                                    onValueChange={(values) => {
                                        const { formattedValue, value } = values;
                                        setFormData({ ...formData, numParkingSpaces: value })
                                    }}
                                    customInput={Form.Control}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="validateParkingSpacesPrice">
                            <Form.Label>* Precio</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <NumberFormat
                                    value={formData.parkingSpacesPrice || ""}
                                    disabled={!formData.additionalParkingSpaces}
                                    required={formData.additionalParkingSpaces}
                                    decimalScale={2}
                                    maxLength="9"
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    onValueChange={(values) => {
                                        const { formattedValue, value } = values;
                                        setFormData({ ...formData, parkingSpacesPrice: value })
                                    }}
                                    customInput={Form.Control}
                                />
                                {
                                    (isSubmitted && (formData.parkingSpacesPrice == "" || formData.parkingSpacesPrice == null) && formData.additionalParkingSpaces) &&
                                    <Alert className="input__alert-error mt-2" theme={"error"} type="error" size={"large"}>
                                        *Campo obligatorio
                                    </Alert>
                                }
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group controlId="">
                            <Form.Label>Bodega</Form.Label>
                            <Form.Check
                                onClick={(e) => {
                                    resetFields("warehouse", !formData.warehouse)
                                }}
                                type="switch"
                                id="storage-switch"
                                label=""
                                defaultChecked={formData.warehouse}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Group controlId="validateMin">
                                    <Form.Label>Mínimo</Form.Label>
                                    <InputGroup>
                                        <NumberFormat
                                            decimalScale={2}
                                            maxLength="9"
                                            allowNegative={false}
                                            value={formData.minWarehouse || ""}
                                            thousandSeparator={true}
                                            disabled={!formData.warehouse}
                                            required={false}
                                            max={formData.maxWarehouse || ""}
                                            onValueChange={async (values) => {
                                                const { formattedValue, value } = values;
                                                if (parseInt(value) > parseInt(formData.maxWarehouse) || !formData.maxWarehouse) {
                                                    await setFormData({ ...formData, maxWarehouse: value, minWarehouse: value })
                                                } else {

                                                    await setFormData({ ...formData, minWarehouse: value })
                                                }
                                            }}
                                            customInput={Form.Control}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text>m<sup>2</sup></InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="validateMax">
                                    <Form.Label>Máximo</Form.Label>
                                    <InputGroup>
                                        <NumberFormat
                                            value={formData.maxWarehouse || ""}
                                            disabled={!formData.warehouse}
                                            decimalScale={2}
                                            maxLength="9"
                                            allowNegative={false}
                                            required={false}
                                            thousandSeparator={true}
                                            onValueChange={async (values) => {
                                                const { formattedValue, value } = values;
                                                await setFormData({ ...formData, maxWarehouse: value })
                                                //validateMax(value);
                                            }}
                                            customInput={Form.Control}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text>m<sup>2</sup></InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    {
                                        (parseInt(formData.maxWarehouse) < parseInt(formData.minWarehouse)) &&
                                        <Alert className="input__alert-error mt-2" theme={"error"} type="error" size={"large"}>
                                            El máximo de bodegas debe ser mayor al minimo
                                        </Alert>
                                    }
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                {
                                    (errorMessageWarehouse) &&
                                    <Alert className="input__alert-error" theme={"error"} type="error" size={"large"}>
                                        {errorMessageWarehouse}
                                    </Alert>
                                }
                            </Col>
                        </Row>

                    </Col>
                    <Col>
                        <Form.Group controlId="validatePrice">
                            <Form.Label>* Precio por m<sup>2</sup></Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <NumberFormat
                                    decimalScale={2}
                                    maxLength="9"
                                    allowNegative={false}
                                    value={formData.priceM2Warehouse || ""}
                                    disabled={!formData.warehouse}
                                    required={formData.warehouse}
                                    thousandSeparator={true}
                                    onValueChange={async (values) => {
                                        const { formattedValue, value } = values;
                                        await setFormData({ ...formData, priceM2Warehouse: value })
                                    }}
                                    customInput={Form.Control}
                                />
                                {
                                    (isSubmitted && (formData.priceM2Warehouse == "" || formData.priceM2Warehouse == null) && formData.warehouse) &&
                                    <Alert className="input__alert-error mt-2" theme={"error"} type="error" size={"large"}>
                                        *Campo obligatorio
                                    </Alert>
                                }
                            </InputGroup>
                        </Form.Group>
                    </Col>

                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group controlId="">
                            <Form.Label>Pool de estacionamiento</Form.Label>
                            <Form.Check
                                onClick={(e) => {
                                    resetFields("parkingPool", !formData.poolParking)

                                }}
                                type="switch"
                                id="parking-pool-switch"
                                defaultChecked={formData.poolParking}

                                label=""
                            />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group controlId="validateMaxAccess">
                            <Form.Label>Accesos máximos</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>#</InputGroup.Text>
                                </InputGroup.Prepend>
                                <NumberFormat
                                    decimalScale={0}
                                    maxLength="9"
                                    allowNegative={false}
                                    value={formData.maxAccess || ""}
                                    disabled={!formData.poolParking}
                                    required={false}
                                    thousandSeparator={true}
                                    onValueChange={async (values) => {
                                        const { formattedValue, value } = values;
                                        await setFormData({ ...formData, maxAccess: value })
                                    }}
                                    customInput={Form.Control}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="validateAccessPrice">
                            <Form.Label>* Precio unitario</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <NumberFormat
                                    decimalScale={2}
                                    maxLength="9"
                                    allowNegative={false}
                                    value={formData.accessPrice}
                                    disabled={!formData.poolParking}
                                    required={formData.poolParking}
                                    thousandSeparator={true}
                                    onValueChange={async (values) => {
                                        const { formattedValue, value } = values;
                                        await setFormData({ ...formData, accessPrice: value })
                                    }}
                                    customInput={Form.Control}
                                />
                                {
                                    (isSubmitted && (formData.accessPrice == "" || formData.accessPrice == null) && formData.poolParking) &&
                                    <Alert className="input__alert-error mt-2" theme={"error"} type="error" size={"large"}>
                                        *Campo obligatorio
                                    </Alert>
                                }
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>

                    </Col>
                    <Col>
                        <Form.Group controlId="validateParkingRelation">
                            <Form.Label>Relación de tarjeta a cajón</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>#</InputGroup.Text>
                                </InputGroup.Prepend>
                                <NumberFormat
                                    decimalScale={2}
                                    maxLength="9"
                                    allowNegative={false}
                                    value={formData.parkingRelation || ""}
                                    disabled={!formData.poolParking}
                                    required={false}
                                    thousandSeparator={true}
                                    onValueChange={async (values) => {
                                        const { formattedValue, value } = values;
                                        await setFormData({ ...formData, parkingRelation: value })
                                    }}
                                    customInput={Form.Control}
                                />
                                <Form.Control.Feedback type="invalid">
                                    *Campo obligatorio
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="validateAsignedParkingLots">
                            <Form.Label>Cajones asignados a pool</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>#</InputGroup.Text>
                                </InputGroup.Prepend>
                                <NumberFormat
                                    decimalScale={0}
                                    maxLength="9"
                                    allowNegative={false}
                                    value={formData.asignedParkingLots || ""}
                                    disabled={!formData.poolParking}
                                    required={false}
                                    thousandSeparator={true}
                                    onValueChange={async (values) => {
                                        const { formattedValue, value } = values;
                                        await setFormData({ ...formData, asignedParkingLots: value })
                                    }}
                                    customInput={Form.Control}
                                />
                                <Form.Control.Feedback type="invalid">
                                    *Campo obligatorio
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group controlId="">
                            <Form.Label>Estacionamiento en plaza</Form.Label>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Group controlId="validateMin">
                                    <Form.Label>Tipo de tarifa</Form.Label>
                                    {/* <Dropdown value={formData.rateType} options={[{ label: 'Gratis', value: 1 }, { label: 'Con cobro', value: 2 }]} onChange={(e) => { changeRateType(e.value) }} optionLabel="label" optionValue="value" className="w-100" /> */}
                                    <Dropdown value={formData.rateType} options={parkingFeeType} onChange={(e) => { changeRateType(e.value) }} optionLabel="name" optionValue="id" className="w-100" />
                                </Form.Group>
                            </Col>
                            <Col>

                                <Form.Group controlId="validatePrice">
                                    <Form.Label>Precio por hora</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <NumberFormat
                                            decimalScale={2}
                                            maxLength="9"
                                            allowNegative={false}
                                            value={formData.priceParkingPlaza || ""}
                                            disabled={parkingPlaza}
                                            required={parkingPlaza}
                                            thousandSeparator={true}
                                            onValueChange={async (values) => {
                                                const { _, value } = values;
                                                await setFormData({ ...formData, priceParkingPlaza: value })
                                            }}
                                            customInput={Form.Control}
                                        />
                                        {
                                            (isSubmitted && (formData.priceParkingPlaza == "" || formData.priceParkingPlaza == null) && (formData.rateType?.value == 2)) &&
                                            <Alert className="input__alert-error mt-2" theme={"error"} type="error" size={"large"}>
                                                *Campo obligatorio
                                            </Alert>
                                        }
                                    </InputGroup>
                                </Form.Group>

                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Form.Group controlId="validatePrice">
                            <Form.Label>Métodos de pago</Form.Label>
                            {parkingPaymentMethods.map((item, index) => {
                                return (
                                    <Form.Check checked={formData.paymentMethods?.includes(item.id) ?? false} key={index} style={{ display: 'inline-block', marginRight: '1em' }} type="checkbox" label={item.name} value={item.id} disabled={parkingPlaza} onChange={async (e) => changePaymentMethods(e.target)} />
                                )
                            })}
                        </Form.Group>
                    </Col>
                </Form.Row>
            </form>
        </section>
    )
}
export default SalesAdditionals