import React, { useEffect, useState } from 'react'

import { useMainContext } from "../../../contexts/MainContext";
import { RestActions } from "../../../actions";
//components
import ModalConfirmImport from "../../../components/atoms/ModalConfirmImport";
import ModalCreateCat from '../../../components/atoms/ModalCreateCat';
import ModalSaveMP from '../../../components/atoms/ModalSaveMP';
import ModalSaveAmenities from '../../../components/atoms/ModalSaveAmenities';
import ModalSaveClassification from '../../../components/atoms/ModalSaveClassification';
import ModalSaveComplex from '../../../components/atoms/ModalSaveComplex';
import ModalSaveCB from '../../../components/atoms/ModalSaveCB';
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
import MenuPhases from '../../../components/molecules/MenuAdmin';
import Dropdown from "../../../components/atoms/Dropdown";
import TableCatalogs from "./TableCatalogs"
import TableAmenities from "./TableAmenities"
import TableMasterPlan from "./TableMasterPlan"
import TableClassification from "./TableClassification"
import TableComplex from "./TableComplex"
import TableOffice from "./TableOffice"
import TableModelType from "./TableCB"
import { useCatalogsContext } from "../../../contexts/CatalogsContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './styles.css'

import ModalSaveCommercialCategory from '../../../components/atoms/ModalSaveCommercialCategory';
import TableCommercialCategory from './TableCommercialCategory';

import ModalSaveSubCommercialCategory from '../../../components/atoms/ModalSaveSubCommercialCategory';
import TableSubCommercialCategory from './TableSubCommercialCategory';

import ModalSaveReferenceM2Groups from '../../../components/atoms/ModalSaveReferenceM2Groups';
import TableReferenceM2Groups from './TableReferenceM2Groups';

export default function Index({ preloadReady }) {
	let catalogs = useCatalogsContext();
	const rest = new RestActions();
	let mainContext = useMainContext();
	const [pageLimit, setPageLimit] = useState(10);
	const [dataTable, setDataTable] = useState({});
	const [idCatalog, setiDCatalog] = useState(false);
	const [idItem, setIdItem] = useState(false);
	const [deleteEndpoint, setDeleteEndpoint] = useState(false);
	const [nameCatalog, setMameCatalog] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [showModalDeleteConfirm, setShowModalDeleteConfirm] = useState(false);
	const [edit, setEdit] = useState(false)
	const [dataCat, setDataCat] = useState({})
	const [hasStatus, setHasStatus] = useState(false)
	//master plan
	const [showModalMP, setShowModalMP] = useState(false);
	const [dataTableMasterPlan, setDataTableMasterPlan] = useState({});
	const [dataMP, setDataMP] = useState({})
	// amenities
	const [showModalAmenities, setShowModalAmenities] = useState(false);
	const [dataTableAmenities, setDataTableAmenities] = useState({}); // listado de tabla amenidades
	const [dataAmenities, setDataAmenities] = useState({}) // data  amenidad especifica
	// classification
	const [showModalClassification, setShowModalClassification] = useState(false);
	const [dataTableClassification, setDataTableClassification] = useState({}); // listado de tabla amenidades
	const [dataClassification, setDataClassification] = useState({}) // data  amenidad especifica
	// completos
	const [showModalComplex, setShowModalComplex] = useState(false);
	const [dataTableComplex, setDataTableComplex] = useState({}); // listado de complejos
	const [dataComplex, setDataComplex] = useState({}) // data  complejo  especifica
	//tipos de  modelo
	const [showModalModelType, setShowModalModelType] = useState(false);
	const [dataTableModelType, setDataTableModelType] = useState({}); // listado de tabla tipos de
	const [dataModelType, setDataModelType] = useState({}) // data  amenidad especifica
	//office
	const [showModalOffice, setShowModalOffice] = useState(false);
	const [dataTableOffice, setDataTableOffice] = useState({}); // listado de tabla oficinas
	const [dataOffice, setDataOffice] = useState({}) // data  oficina especifica
	// CATEGORÍAS COMERCIALES POR GIRO
	const [showModalCommercialCategory, setShowModalCommercialCategory] = useState(false);
	const [dataTableCommercialCategory, setDataTableCommercialCategory] = useState({}); // listado de tabla 
	const [dataCommercialCategory, setDataCommercialCategory] = useState({}) // data especifica
	const [valueDataCommercialCategory, setValueDataCommercialCategory] = useState('') // data
	const [filteredDataCommercialCategory, setFilteredDataCommercialCategory] = useState({}) // data
	const { commercialCategories, setCommercialCategories } = useCatalogsContext(); // Asignar valores al catalog del contexto

	// SUBCATEGORÍAS COMERCIALES
	const [showModalSubCommercialCategory, setShowModalSubCommercialCategory] = useState(false);
	const [dataTableSubCommercialCategory, setDataTableSubCommercialCategory] = useState({}); // listado de tabla
	const [dataSubCommercialCategory, setDataSubCommercialCategory] = useState({}) // data especifica
	const [valueDataSubCommercialCategory, setValueDataSubCommercialCategory] = useState('') // data
	const [filteredDataSubCommercialCategory, setFilteredDataSubCommercialCategory] = useState({}) // data
	const { subCommercialCategories, setSubCommercialCategories } = useCatalogsContext(); // Asignar valores al catalog del contexto

	//GRUPOS DE M2 DE REFERENCIA
	const [showModalReferenceM2Groups, setShowModalReferenceM2Groups] = useState(false);
	const [dataTableReferenceM2Groups, setDataTableReferenceM2Groups] = useState({}); // listado de tabla
	const [dataReferenceM2Groups, setDataReferenceM2Groups] = useState({}) // data especifica
	const [valueDataReferenceM2Groups, setValueDataReferenceM2Groups] = useState('') // data
	const [filteredDataReferenceM2Groups, setFilteredDataReferenceM2Groups] = useState({}) // data
	const [optionsM2ReferenceSubcategory, setOptionsM2ReferenceSubcategory] = useState([]);
	// referenceM2Groups
	//ReferenceM2Groups
	const { setStates } = useCatalogsContext();
	const { setCities } = useCatalogsContext();

	useEffect(() => {
		mainContext.setIsLoading(true);
		if (catalogs.generalCats) {
			mainContext.setIsLoading(false);
		}

	}, [catalogs]);

	async function fetchData() {
		if (!catalogs.states) {
			try {
				let response = await rest.postNew("getAllStates");
				setStates(response);
			}
			catch (error) {/*TODO SOMETHING*/ }
		}
		if (!catalogs.cities) {
			try {
				let response = await rest.postNew("getAllCities");
				setCities(response);
			}
			catch (error) {/*TODO SOMETHING*/ }
		}
	}

	useEffect(() => {
		if (preloadReady) {
			loadMasterPlan(1)
			loadAmenities(1)
			loadCommercialCategory(1)
			loadSubCommercialCategory(1)
			loadReferenceM2Groups(1)
			loadClassifications(1)
			loadModelTypes(1)
			loadComplex(1)
			//loaOffices(1)
		}
		fetchData();
	}, [preloadReady]);
	// load Tables
	const selectCatalogs = async (id, _pageNumber) => {

		if (!id) {
			setDataTable({ data: [] });
			return setHasStatus(false);
		}

		setHasStatus(false)
		setiDCatalog(id)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.postNew("getGeneralCats", {
				catalogID: id, pageLimit: pageLimit, pageNumber: _pageNumber
			});

			setDataTable(response);
			setHasStatus(response.data[0]?.hasOwnProperty("status"));
			mainContext.setIsLoading(false);

		}
		catch (error) {
			mainContext.setMessageAlert(error);
		}
	}
	const loadAmenities = async (_pageNumber) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getAmenitiesAdmin", {
				pageLimit: pageLimit, pageNumber: _pageNumber
			});

			setDataTableAmenities(response)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}

	// CATEGORÍAS COMERCIALES POR GIRO ******************************************************************
	const loadCommercialCategory = async (_pageNumber) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.postNew("getCommercialCategories", {
				pageLimit: pageLimit, pageNumber: _pageNumber
			});

			setCommercialCategories(response.data);
			setDataTableCommercialCategory(response);
			setFilteredDataCommercialCategory(response);

			mainContext.setIsLoading(false);
		}
		catch (error) {/*TODO SOMETHING*/ }
	}

	const filterDataCommercialCategory = async () => {
		let data_filter;
		if (valueDataCommercialCategory != "") {
			data_filter = dataTableCommercialCategory.data.filter((item) => {
				return item.name.toLowerCase().includes(valueDataCommercialCategory.toLowerCase()) || item.namecommercialbusiness.toLowerCase().includes(valueDataCommercialCategory.toLowerCase());
			});
		} else {
			data_filter = dataTableCommercialCategory.data;
		}
		setFilteredDataCommercialCategory({ ...dataTableCommercialCategory, data: data_filter });
	};

	useEffect(() => {
		// filterDataCommercialCategory();
	}, [valueDataCommercialCategory]);

	// END CATEGORÍAS COMERCIALES POR GIRO ******************************************************************


	// SUBCATEGORÍAS COMERCIALES ******************************************************************
	const loadSubCommercialCategory = async (_pageNumber) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.postNew("getSubCommercialCategories", {
				pageLimit: pageLimit, pageNumber: _pageNumber
			});

			setSubCommercialCategories(response.data);
			setDataTableSubCommercialCategory(response);
			setFilteredDataSubCommercialCategory(response);

			mainContext.setIsLoading(false);
		}
		catch (error) {/*TODO SOMETHING*/ }
	}

	const filterDataSubCommercialCategory = async () => {
		let data_filter;
		if (valueDataSubCommercialCategory != "") {
			data_filter = dataTableSubCommercialCategory.data.filter((item) => {
				return item.name.toLowerCase().includes(valueDataSubCommercialCategory.toLowerCase()) || item.namecommercialbusiness.toLowerCase().includes(valueDataSubCommercialCategory.toLowerCase());
			});
		} else {
			data_filter = dataTableSubCommercialCategory.data;
		}
		setFilteredDataSubCommercialCategory({ ...dataTableSubCommercialCategory, data: data_filter });
	};

	useEffect(() => {
		// filterDataSubCommercialCategory();
	}, [valueDataSubCommercialCategory]);

	// END SUBCATEGORÍAS COMERCIALES ******************************************************************



	// GRUPOS DE M2 DE REFERENCIA ******************************************************************
	const loadReferenceM2Groups = async (_pageNumber) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.postNew("getReferenceM2Groups", {
				pageLimit: pageLimit, pageNumber: _pageNumber
			});

			setDataTableReferenceM2Groups(response);
			setFilteredDataReferenceM2Groups(response);

			let optionsM2Reference = await rest.postNew("getOptionsM2ReferenceSubcategory");
			setOptionsM2ReferenceSubcategory(optionsM2Reference);

			mainContext.setIsLoading(false);
		}
		catch (error) {/*TODO SOMETHING*/ }
	}

	const filterDataReferenceM2Groups = async () => {
		let data_filter;
		if (valueDataReferenceM2Groups != "") {
			data_filter = dataTableReferenceM2Groups.data.filter((item) => {
				return item.name.toLowerCase().includes(valueDataReferenceM2Groups.toLowerCase()) || item.namecommercialbusiness.toLowerCase().includes(valueDataReferenceM2Groups.toLowerCase());
			});
		} else {
			data_filter = dataTableReferenceM2Groups.data;
		}
		setFilteredDataReferenceM2Groups({ ...dataTableReferenceM2Groups, data: data_filter });
	};

	useEffect(() => {
		// filterDataReferenceM2Groups();
	}, [valueDataReferenceM2Groups]);

	// END GRUPOS DE M2 DE REFERENCIA ******************************************************************



	const loadMasterPlan = async (_pageNumber) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getMasterPlans", {
				pageLimit: pageLimit, pageNumber: _pageNumber
			});

			setDataTableMasterPlan(response)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}
	const loadClassifications = async (_pageNumber) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getClassifications", {
				pageLimit: pageLimit, pageNumber: _pageNumber
			});

			setDataTableClassification(response)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}
	const loadModelTypes = async (_pageNumber) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getModelTypes", {
				pageLimit: pageLimit, pageNumber: _pageNumber
			});

			setDataTableModelType(response)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}
	const loadComplex = async (_pageNumber) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getComplex", {
				pageLimit: pageLimit, pageNumber: _pageNumber
			});

			setDataTableComplex(response)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}
	//const loaOffices = async (_pageNumber) => {
	//	mainContext.setIsLoading(true);
	//	try {
	//		let response = await rest.post("getOffices", {
	//			pageLimit: pageLimit, pageNumber: _pageNumber
	//		});

	//		setDataTableOffice(response)
	//		mainContext.setIsLoading(false);

	//	}
	//	catch (error) {/*TODO SOMETHING*/ }
	//}
	// end load tables

	const openModalDelete = async (itemID, endpoint) => {
		setIdItem(itemID)
		setDeleteEndpoint(endpoint)
		setShowModalDelete(true)
	}
	const acceptDeleteItem = async () => {
		let deleteParams = {}


		if (deleteEndpoint == "deleteGeneralCat")
			deleteParams = {
				catalogID: idCatalog,
				itemID: idItem,
			}
		else if (deleteEndpoint == "deleteMasterPlan")
			deleteParams = {
				masterPlanID: idItem,
			}
		else if (deleteEndpoint == "deleteAmenityAdmin")
			deleteParams = {
				amenityID: idItem,
			}
		else if (deleteEndpoint == "deleteCommercialCategory")
			deleteParams = {
				commercialCategoryID: idItem,
			}
		else if (deleteEndpoint == "deleteSubCommercialCategory")
			deleteParams = {
				subCommercialCategoryID: idItem,
			}
		else if (deleteEndpoint == "deleteReferenceM2Group")
			deleteParams = {
				referenceM2GroupID: idItem,
			}
		else if (deleteEndpoint == "deleteClassification")
			deleteParams = {
				classificationID: idItem,
			}
		else if (deleteEndpoint == "deleteModelType")
			deleteParams = {
				modelTypeID: idItem,
			}
		else if (deleteEndpoint == "deleteComplex")
			deleteParams = {
				complexID: idItem,
			}


		try {
			let response = {}
			if (deleteEndpoint == 'deleteCommercialCategory' || deleteEndpoint == 'deleteSubCommercialCategory' || deleteEndpoint == 'deleteReferenceM2Group' || deleteEndpoint == 'deleteGeneralCat') {
				response = await rest.postNew(deleteEndpoint, deleteParams);
			} else {
				response = await rest.post(deleteEndpoint, deleteParams);
			}
			closeModal()
			mainContext.setMessageAlert("Elemento  borrado correctamente");
			if (deleteEndpoint == "deleteGeneralCat")
				selectCatalogs(idCatalog, 1) // actualiza la tabla de catalogos
			else if (deleteEndpoint == "deleteMasterPlan")
				loadMasterPlan(1)
			else if (deleteEndpoint == "deleteAmenityAdmin")
				loadAmenities(1)
			else if (deleteEndpoint == "deleteCommercialCategory")
				loadCommercialCategory(1)
			else if (deleteEndpoint == "deleteSubCommercialCategory")
				loadSubCommercialCategory(1)
			else if (deleteEndpoint == "deleteReferenceM2Group")
				loadReferenceM2Groups(1)
			else if (deleteEndpoint == "deleteClassification")
				loadClassifications(1)
			else if (deleteEndpoint == "deleteModelType")
				loadModelTypes(1)
			else if (deleteEndpoint == "deleteComplex")
				loadComplex(1)
		}
		catch (error) {
			closeModal()
			mainContext.setMessageAlert(error);
		}
	}
	const saveCat = async (formaData) => {
		let saveData = {}
		if (formaData.hasOwnProperty("status"))
			saveData = {
				catalogID: idCatalog,
				name: formaData.name.trim(),
				itemID: formaData.itemID,
				status: formaData.status.id == 1 ? true : false
			}
		else
			saveData = {
				catalogID: idCatalog,
				itemID: formaData.itemID,
				name: formaData.name,
			}

		mainContext.setIsLoading(true);
		if (!edit)
			try {
				let response = await rest.postNew("createGeneralCat", saveData);
				await mainContext.setUpdateCatalogs(true)
				closeModal()
				mainContext.setMessageAlert("Catalogo creado correctamente");
				selectCatalogs(idCatalog, 1) // actualiza la tabla de catalogos
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
		else
			try {
				let response = await rest.postNew("updateGeneralCat", saveData);
				await mainContext.setUpdateCatalogs(true)
				closeModal()
				mainContext.setMessageAlert("Catalogo actualizado correctamente");
				selectCatalogs(idCatalog, 1) // actualiza la tabla de catalogos
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
	}
	const saveMP = async (formaData) => {

		let saveData = {
			name: formaData.name.trim(),
			developerID: formaData.developerID.developerID,
			masterPlanID: formaData.masterPlanID,
			status: formaData.status.id == 1 ? true : false,
		}


		mainContext.setIsLoading(true);
		if (!edit)
			try {
				let response = await rest.post("createMasterPlan", saveData);
				closeModal()
				mainContext.setMessageAlert("Catalogo creado correctamente");
				loadMasterPlan(1) // actualiza la tabla de master plan
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
		else
			try {
				let response = await rest.post("updateMasterPlan", saveData);
				closeModal()
				mainContext.setMessageAlert("Catalogo actualizado correctamente");
				loadMasterPlan(1) // actualiza la tabla de master plan
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
	}

	const saveAmenities = async (formaData) => {
		mainContext.setIsLoading(true);

		if (!edit)
			try {
				let response = await rest.post("createAmenityAdmin", {
					name: formaData.name.trim(),
					amenityTypeID: formaData.id.id,
					amenityID: formaData.amenityID,
				});
				closeModal()
				mainContext.setMessageAlert("Amenidad creada correctamente");
				loadAmenities(1) // actualiza la tabla de master plan
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}

		else
			try {
				let response = await rest.post("updateAmenityAdmin", {
					name: formaData.name,
					amenityTypeID: formaData.id.id,
					amenityID: formaData.amenityID,
				});
				closeModal()
				mainContext.setMessageAlert("Amenidad actualizada correctamente");
				loadAmenities(1) // actualiza la tabla de master plan
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
	}

	const saveCommercialCategory = async (formaData) => {
		mainContext.setIsLoading(true);

		if (!edit)
			try {
				let response = await rest.postNew("createCommercialCategory", {
					name: formaData.name.trim(),
					commercialBusinessID: formaData.commercialBusinessID,
				});
				closeModal()
				mainContext.setMessageAlert("Categoria comercial por giro creada correctamente");
				loadCommercialCategory(1)
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}

		else
			try {
				let response = await rest.postNew("updateCommercialCategory", {
					commercialBusinessID: formaData.commercialBusinessID,
					commercialCategoryID: formaData.commercialCategoriesID,
					name: formaData.name,
				});
				closeModal()
				mainContext.setMessageAlert("Categoria comercial por giro actualizada correctamente");
				loadCommercialCategory(1)
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
	}

	const saveSubCommercialCategory = async (formaData) => {
		mainContext.setIsLoading(true);

		if (!edit)
			try {
				let response = await rest.postNew("createSubCommercialCategory", {
					name: formaData.name.trim(),
					commercialCategoriesID: formaData.commercialCategoriesID,
				});
				closeModal()
				mainContext.setMessageAlert("SUBCategoria comercial creada correctamente");
				loadSubCommercialCategory(1)
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}

		else
			try {

				let response = await rest.postNew("updateSubCommercialCategory", {
					subCommercialCategoryID: formaData.subCommercialCategoriesID,
					commercialCategoriesID: formaData.commercialCategoriesID,
					name: formaData.name,
				});
				closeModal()
				mainContext.setMessageAlert("SUBCategoria comercial actualizada correctamente");
				loadSubCommercialCategory(1)
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
	}

	const saveReferenceM2Groups = async (formaData) => {
		mainContext.setIsLoading(true);
		if (!edit)
			try {
				let response = await rest.postNew("createReferenceM2Group", formaData);
				if (response === undefined) {
					mainContext.setMessageAlert("Error al crear grupo de m2");
				} else {
					mainContext.setMessageAlert("Grupo de m2 creado correctamente");
				}

				closeModal()
				loadReferenceM2Groups(1)
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}

		else
			try {
				let response = await rest.postNew("updateReferenceM2Group", formaData);
				closeModal()
				mainContext.setMessageAlert("Grupo de m2 actualizado correctamente");
				loadReferenceM2Groups(1)
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
	}

	const saveClassification = async (formaData) => {
		mainContext.setIsLoading(true);
		if (!edit)
			try {
				let response = await rest.post("createClassification", {
					name: formaData.name.trim(),
					comercialBusinessID: formaData.comercialBusinessID.commercialBusinessID,
					circulationFactor: formaData.circulationFactor
				});
				closeModal()
				mainContext.setMessageAlert("Clasificación creada correctamente");
				loadClassifications(1) // actualiza la tabla de master plan
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
		else
			try {
				let response = await rest.post("updateClassification", {
					name: formaData.name.trim(),
					comercialBusinessID: formaData.comercialBusinessID.commercialBusinessID,
					classificationID: formaData.classificationID,
					circulationFactor: formaData.circulationFactor == "" ? null : formaData.circulationFactor
				});

				closeModal()
				mainContext.setMessageAlert("Clasificación actualizado correctamente");
				loadClassifications(1) // actualiza la tabla de master plan
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
	}
	const saveModelType = async (formaData) => {
		mainContext.setIsLoading(true);
		if (!edit)
			try {
				let response = await rest.post("createModelType", {
					name: formaData.name.trim(),
					comercialBusinessID: formaData.comercialBusinessID.commercialBusinessID,
				});
				closeModal()
				mainContext.setMessageAlert("Tipo giro  creado correctamente");
				loadModelTypes(1) // actualiza la tabla de master plan
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
		else
			try {
				let response = await rest.post("updateModelType", {
					name: formaData.name.trim(),
					comercialBusinessID: formaData.comercialBusinessID.commercialBusinessID,
					modelTypeID: formaData.modelTypeID,

				});

				closeModal()
				mainContext.setMessageAlert("Tipo giro actualizado correctamente");
				loadModelTypes(1) // actualiza la tabla de master plan
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
	}
	const saveComplex = async (formaData) => {
		mainContext.setIsLoading(true);
		let dataSend = {}
		if (!edit)
			dataSend = {
				developerID: formaData.developerID.developerID,
				complexName: formaData.complexName.trim(),
				status: formaData.status.id == 1 ? true : false,
			}
		else
			dataSend = {
				complexName: formaData.complexName.trim(),
				developerID: formaData.developerID.developerID,
				complexID: formaData.complexID,
				developerComplexID: formaData.developerComplexID,
				status: formaData.status.id == 1 ? true : false,
			}

		try {
			let response = await rest.post("saveComplex", dataSend);

			closeModal()
			mainContext.setMessageAlert("Complejo creado correctamente");
			loadComplex(1) // actualiza la tabla de master plan
		}
		catch (error) {
			closeModal()
			mainContext.setIsLoading(false);
			mainContext.setMessageAlert(error);
		}
	}








	const showModalEditCat = async (id) => {

		setEdit(true)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.postNew("getGeneralCat", {
				catalogID: idCatalog,
				itemID: id
			});


			setDataCat(response)
			setShowModal(true)
			mainContext.setIsLoading(false);

		}
		catch (error) {
			closeModal()
			mainContext.setIsLoading(false);
			mainContext.setMessageAlert(error);
		}

	}
	const showModalEditMP = async (id) => {
		setEdit(true)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getMasterPlan", {
				masterPlanID: id
			});


			setDataMP(response)
			setShowModalMP(true)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }

	}
	const showModalEditAmenities = async (id) => {
		setEdit(true)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getAmenityAdmin", {
				amenityID: id
			});


			setDataAmenities(response)
			setShowModalAmenities(true)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }

	}

	const showModalEditCommercialCategory = async (id) => {
		setEdit(true)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.postNew("getCommercialCategory", {
				commercialCategoryID: id
			});


			setDataCommercialCategory(response)
			setShowModalCommercialCategory(true)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}


	const showModalEditSubCommercialCategory = async (id) => {
		setEdit(true)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.postNew("getSubCommercialCategory", {
				subCommercialCategoryID: id
			});

			setDataSubCommercialCategory(response)
			setShowModalSubCommercialCategory(true)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}


	const showModalEditReferenceM2Groups = async (id) => {
		setEdit(true)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.postNew("getReferenceM2Group", {
				referenceM2GroupID: id
			});

			setDataReferenceM2Groups(response)
			setShowModalReferenceM2Groups(true)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}


	const showModalEditClassification = async (id) => {

		setEdit(true)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getClassification", {
				classificationID: id
			});
			setDataClassification(response)
			setShowModalClassification(true)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }

	}
	const showModalEditModelType = async (id) => {

		setEdit(true)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getModelType", {
				modelTypeID: id
			});
			setDataModelType(response)
			setShowModalModelType(true)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }

	}

	const showModalEditComplex = async (id) => {

		setEdit(true)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getComplex", {
				complexID: id
			});
			setDataComplex(response)
			setShowModalComplex(true)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }

	}
	const closeModal = () => {
		setShowModalDelete(false)
		setShowModal(false) // cierra modal Catalogos generales
		setShowModalMP(false) // cierra modal Master plan
		setShowModalAmenities(false) // cierra modal Amenities
		setShowModalCommercialCategory(false) // cierra modal Categoria comercial
		setShowModalSubCommercialCategory(false) // cierra modal de sub categoria comercial
		setShowModalReferenceM2Groups(false) // cierra modal de GRUPOS DE M2 DE REFERENCIA
		setShowModalClassification(false)// cierra modal classification
		setShowModalModelType(false)
		setShowModalComplex(false)
		setEdit(false)
		//mainContext.setIsLoading(false);
	}


	const searchCommercialCategories = async (searchTerm) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.postNew("searchCommercialCategories", {
				searchString: searchTerm
			});

			setFilteredDataCommercialCategory(response);
			mainContext.setIsLoading(false);
		} catch (error) {
			mainContext.setIsLoading(false);
			mainContext.setMessageAlert(error);
		}
	};



	const searchSubCommercialCategories = async (searchTerm) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.postNew("searchSubCommercialCategories", {
				searchString: searchTerm
			});

			setFilteredDataSubCommercialCategory(response);
			mainContext.setIsLoading(false);
		} catch (error) {
			mainContext.setIsLoading(false);
			mainContext.setMessageAlert(error);
		}
	};


	const searchReferenceM2Groups = async (searchTerm) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.postNew("searchReferenceM2Groups", {
				searchString: searchTerm
			});

			setFilteredDataReferenceM2Groups(response);
			mainContext.setIsLoading(false);
		} catch (error) {
			mainContext.setIsLoading(false);
			mainContext.setMessageAlert(error);
		}
	};

	return (
		<div>
			<MenuPhases linkActive={"general-catalogs"} />
			<ModalConfirmImport size="sm" show={showModalDelete} icon={exclamationIcon} handleCancel={closeModal} handleConfirm={acceptDeleteItem}>
				<div className="col">
					<p className="title-modal"><b>¿ Esta seguro de eliminar este elemento ?</b></p>
				</div>
			</ModalConfirmImport>
			<ModalCreateCat showModal={showModal} handlerAccept={saveCat} setShow={closeModal} dataUser={dataCat} edit={edit} nameCatalog={nameCatalog} hasStatus={hasStatus} />
			<ModalSaveMP showModal={showModalMP} handlerAccept={saveMP} setShow={closeModal} dataUser={dataMP} edit={edit} nameCatalog={nameCatalog} hasStatus={hasStatus} />
			<ModalSaveAmenities showModal={showModalAmenities} handlerAccept={saveAmenities} setShow={closeModal} dataUser={dataAmenities} edit={edit} nameCatalog={nameCatalog} hasStatus={hasStatus} />
			<ModalSaveCommercialCategory showModal={showModalCommercialCategory} handlerAccept={saveCommercialCategory} setShow={closeModal} dataUser={dataCommercialCategory} edit={edit} nameCatalog={nameCatalog} hasStatus={hasStatus} />
			<ModalSaveSubCommercialCategory showModal={showModalSubCommercialCategory} handlerAccept={saveSubCommercialCategory} setShow={closeModal} dataUser={dataSubCommercialCategory} edit={edit} nameCatalog={nameCatalog} hasStatus={hasStatus} />
			<ModalSaveReferenceM2Groups showModal={showModalReferenceM2Groups} optionsM2ReferenceSubcategory={optionsM2ReferenceSubcategory} handlerAccept={saveReferenceM2Groups} setShow={closeModal} dataUser={dataReferenceM2Groups} edit={edit} nameCatalog={nameCatalog} hasStatus={hasStatus} />
			<ModalSaveClassification showModal={showModalClassification} handlerAccept={saveClassification} setShow={closeModal} dataUser={dataClassification} edit={edit} nameCatalog={nameCatalog} hasStatus={hasStatus} />
			<ModalSaveComplex showModal={showModalComplex} handlerAccept={saveComplex} setShow={closeModal} dataUser={dataComplex} edit={edit} nameCatalog={nameCatalog} hasStatus={hasStatus} />
			<ModalSaveCB showModal={showModalModelType} handlerAccept={saveModelType} setShow={closeModal} dataUser={dataModelType} edit={edit} nameCatalog={nameCatalog} hasStatus={hasStatus} />
			<div className="col-3">
				<label className="input-label form-label pn-0">
					Catálogos
				</label>
				<div className="pb-2">
					{/*<input type="file" onChange={e => getFileBase64(e.target.files[0])} maxLength="50" className="form-control black-border large-input" />*/}
					<Dropdown options={catalogs.generalCats} onChange={(e) => {
						setMameCatalog(e.value?.name)
						selectCatalogs(e.value?.catalogID || null, 1)
					}} optionValue="catalogID" optionLabel="name" className="w-100" />
				</div>
			</div>
			<section className="mt-4">
				<div className="custom-card">
					<div className="custom-card-header px-3 d-flex">
						<h4 className="py-3 w-50">{nameCatalog}</h4>
						<div className="w-50 py-2 d-flex justify-content-end">
							{idCatalog && <button onClick={() => { setShowModal(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Agregar</span> +</button>}
						</div>
					</div>
					<div className="custom-card-body">
						<div className="table-scroll-y table-scroll-y-scrollable">
							<div className="table-responsive h-100">
								<TableCatalogs handleRefresh={selectCatalogs} data={dataTable} openDeletModal={openModalDelete} openEditModal={showModalEditCat} idCatalog={idCatalog} />

							</div>
						</div>
					</div>
				</div>
			</section>
			{/*------------------AMENIDADES---------------*/}
			<section className="mt-4">
				<div className="custom-card">
					<div className="custom-card-header px-3 d-flex">
						<h4 className="py-3 w-50">AMENIDADES</h4>
						<div className="w-50 py-2 d-flex justify-content-end">
							<button onClick={() => { setShowModalAmenities(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva Amenidad</span> +</button>
						</div>
					</div>
					<div className="custom-card-body">
						<div className="table-scroll-y table-scroll-y-scrollable">
							<div className="table-responsive h-100">
								<TableAmenities handleRefresh={loadAmenities} data={dataTableAmenities} openDeletModal={openModalDelete} openEditModal={showModalEditAmenities} />

							</div>
						</div>
					</div>
				</div>
			</section>
			{/*------------------FIN AMENIDADES---------------*/}
			{/*------------------MASTERPLAN---------------*/}
			<section className="mt-4">
				<div className="custom-card">
					<div className="custom-card-header px-3 d-flex">
						<h4 className="py-3 w-50">MASTER PLAN</h4>
						<div className="w-50 py-2 d-flex justify-content-end">
							<button onClick={() => { setShowModalMP(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nuevo Master Plan</span> +</button>
						</div>
					</div>
					<div className="custom-card-body">
						<div className="table-scroll-y table-scroll-y-scrollable">
							<div className="table-responsive h-100">
								<TableMasterPlan handleRefresh={loadMasterPlan} data={dataTableMasterPlan} openDeletModal={openModalDelete} openEditModal={showModalEditMP} />

							</div>
						</div>
					</div>
				</div>
			</section>
			{/*------------------FIN MASTERPLAN---------------*/}
			{/*------------------CLASIFICACIÓN---------------*/}
			<section className="mt-4">
				<div className="custom-card">
					<div className="custom-card-header px-3 d-flex">
						<h4 className="py-3 w-50">CLASIFICACIÓN</h4>
						<div className="w-50 py-2 d-flex justify-content-end">
							<button onClick={() => { setShowModalClassification(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva Clasificación </span> +</button>
						</div>
					</div>
					<div className="custom-card-body">
						<div className="table-scroll-y table-scroll-y-scrollable">
							<div className="table-responsive h-100">
								<TableClassification handleRefresh={loadClassifications} data={dataTableClassification} openDeletModal={openModalDelete} openEditModal={showModalEditClassification} />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/*------------------FIN CLASIFICACIÓN---------------*/}
			{/*------------------COMPLEJOS---------------*/}
			<section className="mt-4">
				<div className="custom-card">
					<div className="custom-card-header px-3 d-flex">
						<h4 className="py-3 w-50">COMPLEJOS</h4>
						<div className="w-50 py-2 d-flex justify-content-end">
							<button onClick={() => { setShowModalComplex(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nuevo Complejo </span> +</button>
						</div>
					</div>
					<div className="custom-card-body">
						<div className="table-scroll-y table-scroll-y-scrollable">
							<div className="table-responsive h-100">
								<TableComplex handleRefresh={loadComplex} data={dataTableComplex} openDeletModal={openModalDelete} openEditModal={showModalEditComplex} />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/*------------------FIN COMPLEJOS---------------*/}

			{/*------------------TIPO GIRO---------------*/}
			<section className="mt-4">
				<div className="custom-card">
					<div className="custom-card-header px-3 d-flex">
						<h4 className="py-3 w-50">TIPO GIRO</h4>
						<div className="w-50 py-2 d-flex justify-content-end">
							<button onClick={() => { setShowModalModelType(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nuevo Tipo </span> +</button>
						</div>
					</div>
					<div className="custom-card-body">
						<div className="table-scroll-y table-scroll-y-scrollable">
							<div className="table-responsive h-100">
								<TableModelType handleRefresh={loadModelTypes} data={dataTableModelType} openDeletModal={openModalDelete} openEditModal={showModalEditModelType} />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/*------------------CATEGORÍAS COMERCIALES POR GIRO---------------*/}
			<section className="mt-4">
				<div className="custom-card">
					<div className="custom-card-header px-3 d-flex">
						<h4 className="py-3 w-50">CATEGORÍAS COMERCIALES POR GIRO</h4>
						<div className="w-50 py-2 d-flex justify-content-end inputSearchWithIcon" style={{ width: "16em", cssText: "width: 16em !important" }}>
							<FontAwesomeIcon icon={faSearch} />
							<input
								type='text'
								className='form-control'
								placeholder='Search'
								value={valueDataCommercialCategory}
								onInput={(e) => { setValueDataCommercialCategory(e.target.value) }}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										searchCommercialCategories(e.target.value);
									}
								}}
							/>						</div>
						<div className="w-50 py-2 d-flex justify-content-end" style={{ width: "18em", cssText: "width: 18em !important" }}>
							<button onClick={() => { setShowModalCommercialCategory(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva categoria</span> +</button>
						</div>
					</div>
					<div className="custom-card-body">
						<div className="table-scroll-y table-scroll-y-scrollable">
							<div className="table-responsive h-100">
								<TableCommercialCategory handleRefresh={loadCommercialCategory} data={filteredDataCommercialCategory} openDeletModal={openModalDelete} openEditModal={showModalEditCommercialCategory} />

							</div>
						</div>
					</div>
				</div>
			</section>
			{/*------------------FIN CATEGORÍAS COMERCIALES POR GIRO---------------*/}

			{/*------------------SUB CATEGORÍAS COMERCIALES---------------*/}
			<section className="mt-4">
				<div className="custom-card">
					<div className="custom-card-header px-3 d-flex">
						<h4 className="py-3 w-50">SUBCATEGORÍAS COMERCIALES</h4>
						<div className="w-50 py-2 d-flex justify-content-end inputSearchWithIcon" style={{ width: "16em", cssText: "width: 16em !important" }}>
							<FontAwesomeIcon icon={faSearch} />
							<input
								type='text'
								className='form-control'
								placeholder='Search'
								value={valueDataSubCommercialCategory}
								onInput={(e) => { setValueDataSubCommercialCategory(e.target.value) }}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										searchSubCommercialCategories(e.target.value);
									}
								}}
							/>
						</div>
						<div className="w-50 py-2 d-flex justify-content-end" style={{ width: "18em", cssText: "width: 18em !important" }}>
							<button onClick={() => { setShowModalSubCommercialCategory(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva subcategoria</span> +</button>
						</div>
					</div>
					<div className="custom-card-body">
						<div className="table-scroll-y table-scroll-y-scrollable">
							<div className="table-responsive h-100">
								<TableSubCommercialCategory handleRefresh={loadSubCommercialCategory} data={filteredDataSubCommercialCategory} openDeletModal={openModalDelete} openEditModal={showModalEditSubCommercialCategory} />

							</div>
						</div>
					</div>
				</div>
			</section>
			{/*------------------FIN SUB CATEGORÍAS COMERCIALES---------------*/}

			{/*------------------GRUPOS DE M2 DE REFERENCIA---------------*/}
			<section className="mt-4">
				<div className="custom-card">
					<div className="custom-card-header px-3 d-flex">
						<h4 className="py-3 w-50">GRUPOS DE M2 DE REFERENCIA</h4>
						<div className="w-50 py-2 d-flex justify-content-end inputSearchWithIcon" style={{ width: "16em", cssText: "width: 16em !important" }}>
							<FontAwesomeIcon icon={faSearch} />
							<input
								type='text'
								className='form-control'
								placeholder='Search'
								value={valueDataReferenceM2Groups}
								onInput={(e) => { setValueDataReferenceM2Groups(e.target.value) }}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										searchReferenceM2Groups(e.target.value);
									}
								}}
							/>
						</div>
						<div className="w-50 py-2 d-flex justify-content-end" style={{ width: "18em", cssText: "width: 18em !important" }}>
							<button onClick={() => { setShowModalReferenceM2Groups(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva ponderación</span> +</button>
						</div>
					</div>
					<div className="custom-card-body">
						<div className="table-scroll-y table-scroll-y-scrollable">
							<div className="table-responsive h-100">
								<TableReferenceM2Groups handleRefresh={loadReferenceM2Groups} data={filteredDataReferenceM2Groups} openDeletModal={openModalDelete} openEditModal={showModalEditReferenceM2Groups} />

							</div>
						</div>
					</div>
				</div>
			</section>
			{/*------------------FIN GRUPOS DE M2 DE REFERENCIA---------------*/}
			{/* Reference m2 groups */}


			{/*------------------FIN TIPO GITO---------------*/}
			{/*------------------TIPO GIRO---------------*/}
			{/*<section className="mt-4">
				<div className="custom-card">
					<div className="custom-card-header px-3 d-flex">
						<h4 className="py-3 w-50">OFICINA</h4>
						<div className="w-50 py-2 d-flex justify-content-end">
							<button onClick={() => { setShowModalClassification(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva oficina </span> +</button>
						</div>
					</div>
					<div className="custom-card-body">
						<div className="table-scroll-y table-scroll-y-scrollable">
							<div className="table-responsive h-100">
								<TableOffice handleRefresh={loadClassifications} data={dataTableOffice} openDeletModal={openModalDelete} openEditModal={showModalEditClassification} />
							</div>
						</div>
					</div>
				</div>
			</section>*/}
			{/*------------------FIN TIPO GITO---------------*/}
		</div>
	)
}
