import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _, { set } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from 'primereact/tooltip';
//ACTION && CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
//COMPONENTS
import MenuSales from '../../components/molecules/MenuSales';
import ControlForm from './ControlForm';
import ControlView from './ControlView';

import ModalImportFile from '../..//components/atoms/ModalImportFile';
import ModalDeleteGathering from '../..//components/atoms/ModalDeleteGathering';
import ModalConfirmImport from "../../components/atoms/ModalConfirmImport";
import ModalConfirmGeneral from "../../components/atoms/ModalConfirmGeneral";
//ASSETS
import iconEdit from '../../assets/icons/icon-edit.svg';
import exclamationIcon from '../../assets/icons/icon-exclamation-mark.svg';
import checkIcon from '../../assets/icons/icon-check.svg';
import warningIcon from '../../assets/icons/icon-warning.svg'

import CreateNewUprising from './CreateNewUprising';
import Dropdown from "../../components/atoms/Dropdown";
import { useCatalogsContext } from "../../contexts/CatalogsContext";


const Control = ({ preloadReady }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();

    //let { phaseID } = useParams();
    const location = useLocation();
    if (window.sessionStorage.getItem("phaseID") == "" || window.sessionStorage.getItem("phaseID") == undefined)
        window.sessionStorage.setItem("phaseID", location.state.phaseID);
    const phaseID = window.sessionStorage.getItem("phaseID");

    const [phaseData, setPhaseData] = useState({});
    const [inProcess, setInProcess] = useState(false);
    const [hasImported, setHasImported] = useState(false);
    const [newRegisterModal, setNewRegisterModal] = useState(false);
    const [gatheringInfo, setGatheringInfo] = useState([]);
    const [gatheringInfoPlusFilter, setGatheringInfoPlusFilter] = useState([]);
    const [gatheringInformationID, setGatheringInfoID] = useState();
    const [gatheringInformationIDView, setGatheringInfoIDView] = useState();
    //importar 
    const [showModal, setShowModal] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalCreateNewUprising, setShowModalCreateNewUprising] = useState(false);
    const [showModalAcceptDelete, setShowModalAcceptDelete] = useState(false);
    const [showModalLogs, setShowModalLogs] = useState(false);
    const [logsModalData, setLogsModalData] = useState(false);
    const [showModalImportError, setShowModalImportError] = useState(false);
    const [showModalErrorMsg, setShowModalErrorMsg] = useState(false);
    const [showModalhasImported, setShowModalhasImported] = useState(false);
    const [showModalImportSuccess, setShowModalImportSuccess] = useState(false);
    const [showSuccess, setShwoSuccess] = useState(false);
    const [showModalImportWarnings, setShowModalImportWarnings] = useState(false);
    const [showModalImportWarningsList, setShowModalImportWarningsList] = useState(false);
    const [importedFileId, setImportedFileId] = useState("")
    const [namePhase, setNamePhase] = useState("")
    const [optionsCommercialBusinessUses3, setOptionsCommercialBusinessUses3] = useState([]);
    const [dataCurrentPhase, setDataCurrentPhase] = useState([]);
    const [optionsFiltersCommercialBusiness, setOptionsFiltersCommercialBusiness] = useState([]);
    const [showModalConfirmNewUprising, setShowModalConfirmNewUprising] = useState(false);
    const [dataNew, setDataNew] = useState({ comercialBusinessID: {} });

    const [filterData, setFilterData] = useState({
        commercialBusinessFilter: null
    })

    React.useEffect(() => {
        if (gatheringInfo.length > 0) {
            let options = Array.from(
                new Map(
                    gatheringInfo.map(item => [item.commercialBusinessID, {
                        commercialBusinessID: item.commercialBusinessID,
                        description: item.commercialBusinessName
                    }])
                ).values()
            );
            let allOptions = [{ commercialBusinessID: null, description: "---- Todos ----" }];
            setOptionsFiltersCommercialBusiness(allOptions.concat(options));
            setGatheringInfoPlusFilter(gatheringInfo);
        }
    }, [gatheringInfo]);

    useEffect(() => {
        if (preloadReady) {
            initPhase();
            getGatheringInfo();
        }
    }, [preloadReady]);

    const initPhase = async () => {
        mainContext.setIsLoading(true);
        mainContext.setIsDirty(false);
        try {
            let response = await rest.post("getPhases", { phaseID: parseInt(phaseID) });
            setPhaseData(response[0]);

            // console.log('response[0]: ', response[0]);

            const commercialBusinessUses3 = response[0]?.commercialBusinessUses?.filter((item) => item.useTypeID == 3 && item.classificationID != null);
            setOptionsCommercialBusinessUses3(commercialBusinessUses3);
            setDataCurrentPhase({ marketingSchemeID: response[0]?.marketingSchemeID, commercialBusinessID: response[0]?.commercialBusinessID });
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const extractNameCommercialBusiness = (commercialBusinessID) => {
        let commercialBusiness = catalogs.commercialBusiness.find((item) => item.commercialBusinessID == commercialBusinessID);
        return commercialBusiness ? commercialBusiness.description : "---";
    }

    const getGatheringInfo = async () => {
        try {
            let response = await rest.postNew("getGatheringInformation", { phaseID: parseInt(phaseID) });
            response.map((item) => {
                item.commercialBusinessName = extractNameCommercialBusiness(item.commercialBusinessID);
                return item;
            });
            setGatheringInfo(response);

            let _inProcess = _.filter(response, (row) => { return row.status == "En curso" });
            let _isImported = _.filter(response, (row) => { return row.isImported == true });
            if (_isImported.length > 0) {
                setHasImported(true)
            } else {
                setHasImported(false)
            }
            await setInProcess((_inProcess.length));
            await initPhase()
            mainContext.setIsLoading(false);
        }
        catch (error) {
            mainContext.setIsLoading(false);
        }
    }

    const linkViewControlTemplate = (rowData) => {
        return <span className="btn-icon"><FontAwesomeIcon icon={faEye} onClick={() => { setGatheringInfoIDView(rowData.gatheringInformationID) }} /></span>
    }

    const linkEditControlTemplate = (rowData) => {
        return <span className="btn-icon"><img onClick={() => { setGatheringInfoID(rowData.gatheringInformationID) }} src={iconEdit} data-cy={"btn-edit-" + rowData.orderID} /></span>
    }

    const currencyFormatTemplate = (rowData, field) => {
        if (rowData[field.field] && rowData[field.field] != "-")
            return format(rowData[field.field]);
        else
            return rowData[field.field];
    }

    function format(num) {
        return ('' + num).replace(
            /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
            function (m, s1, s2) {
                return s2 || (s1 + ',');
            }
        );
    }
    const acceptImport = async () => {
        mainContext.setIsLoading(true);
        try {
            let response = await rest.post("acceptImportedGathering", {
                phaseID: parseInt(phaseID),
                importedGatheringID: importedFileId
            });

            getGatheringInfo();
            setShowModalImportError(false)
            setShowModalImportSuccess(false)
            setShowModalImportWarnings(false)
            setShowModal(false)
            mainContext.setIsLoading(false);
            setShwoSuccess(true)
            //mainContext.setMessageAlert("Archivo importado correctamente");

        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const validateExcel = async (file, fileName) => {
        mainContext.setIsLoading(true);

        if (file) {
            try {
                let response = await rest.post("importedGhatering", {
                    phaseID: parseInt(phaseID),
                    fileName: fileName,
                    fileData: file
                });
                setImportedFileId(response.importedGatheringID)

                if (response.errorMsg) {
                    await closeModal();
                    await setShowModalImportError(true)
                    await setShowModalErrorMsg(response.errorMsg)
                }
                else {
                    setShowModalImportSuccess(true)
                    setShowModalImportWarningsList(response)
                }
                mainContext.setIsLoading(false);

            }
            catch (error) {
                await closeModal();
                await setShowModalErrorMsg(error)
                await setShowModalImportError(true)
            }
        } else {
            mainContext.setIsLoading(false);

            mainContext.setMessageAlert("Selecciona un archivo para importar");
        }
    }
    const validateHasImported = () => {
        if (hasImported) {
            setShowModalhasImported(true)
        } else {
            setShowModal(true)
        }
    }
    const validateDeleteGathering = () => {

        setShowModalDelete(true)

    }
    const deleteGathering = (name) => {

        setNamePhase(name)
        setShowModalAcceptDelete(true)
    }
    const confirmDeleteCathering = async () => {

        if (namePhase) {
            try {
                let response = await rest.post("deleteGathering", {
                    phaseID: parseInt(phaseID),
                    phaseName: namePhase,

                });

                await setNamePhase(null)
                await getGatheringInfo()

                if (response.code) {
                    await closeModal();
                    await setNamePhase(null)
                    await mainContext.setMessageAlert(response.msg)
                    await getGatheringInfo()
                }
                else if (response.phaseID) {
                    await closeModal();
                    await setNamePhase(null)
                    await mainContext.setMessageAlert(`Los levantamientos de la fase ${response.phaseID}  se han eliminado de manera correcta`)
                    await getGatheringInfo()

                }
                mainContext.setIsLoading(false);

            }
            catch (error) {
                await closeModal();
                await setShowModalErrorMsg(error)
                await setShowModalImportError(true)
            }
        } else {
            mainContext.setIsLoading(false);

            mainContext.setMessageAlert("Ingresa un nombre");
        }
    }

    // CREAR NUEVO LEVANTAMIENTO

    // const validateCreateNewUprising = () => {
    //     setShowModalCreateNewUprising(true)
    // }

    const handleCreateNewUprising = (formDataNew) => {

        setDataNew(formDataNew);
        setShowModalCreateNewUprising(false);
        setShowModalConfirmNewUprising(true);

        // FUNCIONALIDAD PARA EL FILTRO
        // setNamePhase(name)
    }

    const handleConfirmCreateNewUprising = () => {
        setShowModalConfirmNewUprising(false);
        setNewRegisterModal(true);
    }

    // END CREAR NUEVO LEVANTAMIENTO


    const onChangeFilterData = (name, value) => {
        let filter = gatheringInfo.filter((item) => {
            if (value) {
                return item.commercialBusinessID == value.commercialBusinessID
            } else {
                return item
            }
        });
        setGatheringInfoPlusFilter(filter);
        setFilterData({ ...filterData, [name]: value });
    };

    const cancelImport = async () => {
        try {
            let response = await rest.post("cancelImportedGathering", {
                importedGatheringID: importedFileId
            });

            setShowModalImportError(false)
            setShowModalImportSuccess(false)
            setShowModalImportWarnings(false)
            setShowModal(false)
            setShowModalhasImported(false)
        }
        catch (error) {/*TODO SOMETHING*/ }
    }
    const closeModal = (dato) => {
        setShowModalImportError(false)
        setShowModalImportSuccess(false)
        setShowModalImportWarnings(false)
        setShowModal(false)
        setShwoSuccess(false)
        mainContext.setIsLoading(false);
        setShowModalhasImported(false)
        setShowModalDelete(false)
        setShowModalCreateNewUprising(false)
        setShowModalAcceptDelete(false)
    }
    return (
        (preloadReady) &&
        <>
            <CreateNewUprising showModal={showModalCreateNewUprising} handlerAccept={handleCreateNewUprising} setShow={setShowModalCreateNewUprising} optionsCommercialBusinessUses3={optionsCommercialBusinessUses3} optionMarketingSchemes={dataCurrentPhase?.marketingSchemeID} />
            <ModalConfirmGeneral show={showModalConfirmNewUprising} icon={exclamationIcon} handleConfirm={handleConfirmCreateNewUprising} footerButtons={false} handleCancel={() => { setShowModalConfirmNewUprising(false) }}>
                <div className="col">
                    <p className="title-modal"><b>¿Seguro que deseas crear un nuevo levantamiento?</b></p>
                    <p>Tipo de levantamiento: <strong>{dataNew.typeLifting?.name}</strong></p>
                    <p>Giro del Levantamiento: <strong>{dataNew.comercialBusinessID?.description}</strong></p>
                </div>
            </ModalConfirmGeneral>

            <MenuSales phaseID={phaseID} phaseData={phaseData} linkActive={"control"} />
            <ModalImportFile showModal={showModal} handlerAccept={validateExcel} setShow={setShowModal} />
            <ModalDeleteGathering showModal={showModalDelete} handlerAccept={deleteGathering} setShow={setShowModalDelete} />
            <ModalConfirmImport show={showModalImportError} icon={exclamationIcon} handleConfirm={closeModal} footerButtons={false}>
                <div className="col">

                    <p className="title-modal"><b>Error</b></p>
                    <p className="modal-content-list overflow-auto h-200">
                        {Array.isArray(showModalErrorMsg) ? (showModalErrorMsg.map(err => {
                            return (<li className="text-left">{err}</li>)
                        })) : showModalErrorMsg}
                    </p>
                </div>
            </ModalConfirmImport>
            {/*modal archivo correcto*/}
            <ModalConfirmImport size={"sm"} show={showModalImportSuccess} icon={checkIcon} handleCancel={cancelImport} handleConfirm={acceptImport}>
                <div className="col">
                    <p className="title-modal"><b>Se importarán</b></p>
                    <p className="title-modal m-0">{showModalImportWarningsList.gatheringsCount} levantamientos</p>

                </div>
            </ModalConfirmImport>
            <ModalConfirmImport hideCancel={true} footerButtons={true} size={"sm"} show={showSuccess} icon={checkIcon} handleCancel={closeModal} handleConfirm={closeModal}>
                <div className="col">
                    <p className="title-modal"><b>La importación fue exitosa</b></p>


                </div>
            </ModalConfirmImport>

            <ModalConfirmImport show={showModalImportWarnings} icon={warningIcon} handleCancel={cancelImport} handleConfirm={acceptImport}>
                <div className="col">
                    <p className="title-modal"><b>Warnings</b></p>
                    <p className="modal-content-list overflow-auto h-400 border border-dark p-3">
                        {showModalErrorMsg}
                    </p>

                </div>
            </ModalConfirmImport>
            <ModalConfirmImport size={"sm"} show={showModalhasImported} icon={warningIcon} handleCancel={closeModal} handleConfirm={() => { setShowModalhasImported(false); setShowModal(true) }}>
                <div className="col">

                    <p className="title-modal"><b>Se había realizado una importación previa de levantamientos</b></p>
                    <p className="modal-content-list overflow-auto h-400 p-3">
                        Los levantamientos previamente importados se sobreescribirán
                    </p>

                </div>
            </ModalConfirmImport>

            <ModalConfirmGeneral handleConfirm={confirmDeleteCathering} handleCancel={setShowModalAcceptDelete} show={showModalAcceptDelete} icon={exclamationIcon} >
                <div className="col">
                    <p className="title-modal"><b>¿Seguro que deseas eliminar los levantamientos?</b></p>
                    <p>Se borraran los registros  levantamientos de manera permanente</p>
                </div>
            </ModalConfirmGeneral>
            <ControlForm dataCurrentPhase={dataCurrentPhase} dataNew={dataNew} gatheringInformationID={gatheringInformationID} phaseID={phaseID} showModal={(gatheringInformationID || newRegisterModal)} handleNewRegisterModal={setNewRegisterModal} handleGatheringInfoID={setGatheringInfoID} handleRefreshList={getGatheringInfo} phaseData={phaseData} />
            <ControlView gatheringInformationID={gatheringInformationIDView} phaseID={phaseID} showModal={(gatheringInformationIDView)} handleGatheringInfoID={setGatheringInfoIDView} handleRefreshList={getGatheringInfo} />
            <div className="custom-card">
                <div className="custom-card-header px-3 d-flex">
                    <h4 className="py-3 w-50">LEVANTAMIENTOS</h4>
                    <div className="form-group with-message mb-0 w-50 d-flex justify-content-end" style={{ marginRight: '7em', padding: '0', alignItems: 'center' }}>
                        <label className="input-label form-label mb-0" style={{ marginRight: '1em' }}>
                            GIROS:
                        </label>
                        <Dropdown value={filterData.commercialBusinessFilter} options={optionsFiltersCommercialBusiness} onChange={(e) => { onChangeFilterData('commercialBusinessFilter', e.value); }} optionLabel="description" optionValue="commercialBusinessID" width='14em' placeholder='--- Todos ---' />
                    </div>
                    <div className="w-50 py-2 d-flex justify-content-end" style={{ minWidth: '40em' }}>
                        {phaseData.hasGatheringInformation ?
                            <button data-cy="btn-new" onClick={() => { validateDeleteGathering() }} className="btn btn-primary mr-3"><span className="d-inline-block pr-2">Borrar levantamientos</span></button> :
                            <button data-cy="btn-new" className="btn btn-primary disabled mr-3" ><span className="d-inline-block pr-2">Borrar levantamientos</span></button>
                        }

                        {!phaseData ?
                            <button data-cy="btn-new" className="btn btn-primary disabled mr-3" ><span className="d-inline-block pr-2" >Importar levantamientos</span></button> :
                            <button data-cy="btn-new" onClick={() => { validateHasImported() }} className="btn btn-primary mr-3"><span className="d-inline-block pr-2">Importar levantamientos</span></button>}
                        <Tooltip target=".levantamiento" mouseTrack mouseTrackLeft={10} showDelay={100} />
                        {!phaseData.hasUnitsDiffOthers ?
                            <button data-cy="btn-new" className="btn btn-primary levantamiento disabled" data-pr-tooltip="No existen unidades para comercializar" data-pr-position="top"><span className="d-inline-block pr-2" >Nuevo levantamiento</span> +</button> :
                            <button data-cy="btn-new" disabled={inProcess} onClick={() => {
                                // setNewRegisterModal(true)
                                setShowModalCreateNewUprising(true)
                            }} className="btn btn-primary"><span className="d-inline-block pr-2">Nuevo levantamiento</span> +</button>}

                    </div>
                </div>
                <div className="custom-card-body">
                    <div className="row">
                        <div className="col">
                            <DataTable value={gatheringInfoPlusFilter} emptyMessage="No se encontraron datos" className="p-datatable-no-border p-datatable-striped" rows={10000}>
                                <Column sortable className="text-center px-0 w-50px" field="orderID" header="#" />
                                <Column sortable className="text-center px-1 ellipsis" field="gatheringAt" header="Fecha de levantamiento" />
                                <Column sortable className="text-center px-1 ellipsis" field="commercialBusinessName" body={currencyFormatTemplate} header="Giro" />
                                <Column sortable className="text-center px-1 ellipsis" field="salesProgress" body={currencyFormatTemplate} header="% Avance venta" />
                                <Column sortable className="text-center px-1 ellipsis" field="rentProgress" body={currencyFormatTemplate} header="% Avance renta" />
                                <Column sortable className="text-center px-1 ellipsis" field="averageInventory" body={currencyFormatTemplate} header={<span>$ Promedio inventario</span>} />
                                <Column sortable className="text-center px-1 ellipsis" field="m2Sale" body={currencyFormatTemplate} header="$ M2 venta" />
                                <Column sortable className="text-center px-1 ellipsis" field="m2Rent" body={currencyFormatTemplate} header="$ M2 renta" />
                                {/* <Column sortable className="text-center px-1 ellipsis" field="closedAt" header="Fecha de cierre" />
                                <Column sortable className="text-center px-1 ellipsis" field="saleAdvance" body={currencyFormatTemplate} header="% Avance ventas" />
                                <Column sortable className="text-center px-1 ellipsis" field="advanceSoldArea" body={currencyFormatTemplate} header="% Área vendida" />
                                <Column sortable className="text-center px-1 ellipsis" field="averagePrice" body={currencyFormatTemplate} header="Precio promedio de inventario" />
                                <Column sortable className="text-center px-1 ellipsis" field="averageM2" body={currencyFormatTemplate} header={<span>$/M<sup>2</sup> promedio inventario</span>} /> */}
                                <Column sortable className="text-center px-1 ellipsis" field="status" header="Estado" />
                                <Column sortable className="text-center px-1 ellipsis" field="isImportedText" header="Tipo" />
                                <Column className="text-center px-1 w-50px" field="" header="" body={linkViewControlTemplate} />
                                <Column className="text-center px-1 w-50px" field="" header="" body={linkEditControlTemplate} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Control;