import React, { useEffect, useState } from 'react';
import { Switch, Route, useParams, useRouteMatch, useLocation } from "react-router-dom";
//ACTION && CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
//COMPONENTS
import SalesAdditionals from './SalesAdditionals';
import SalesSchemas from './SalesSchemas';
import SalesPromotions from './SalesPromotions';
import MenuSales from '../../components/molecules/MenuSales';
//ASSETS

import { useCatalogsContext } from "../../contexts/CatalogsContext";
import { use } from 'react';

const Sales = ({ preloadReady }) => {
    const catalogs = useCatalogsContext();
    const {
        setParkingPaymentMethods,
        setParkingFeeType
    } = catalogs;
    const [parkingPlaza, setParkingPlaza] = useState(true);

    const loadCatalogs = async () => {
        if (!catalogs.parkingPaymentMethods || catalogs.parkingPaymentMethods.length === 0) {
            let resPaymentMethods = await rest.postNew("getParkingPaymentMethods");
            setParkingPaymentMethods(resPaymentMethods);
        }
        if (!catalogs.parkingFeeType || catalogs.parkingFeeType.length === 0) {
            let resPaymentMethods = await rest.postNew("getParkingFeeType");
            setParkingFeeType(resPaymentMethods);
        }
    };

    React.useEffect(() => {
        loadCatalogs();
        setTimeout(() => {
            mainContext.setIsDirty(false);
        }, 500)
    }, [catalogs]);


    const rest = new RestActions();
    let mainContext = useMainContext();
    //let { phaseID } = useParams();
    const location = useLocation();
    if (window.sessionStorage.getItem("phaseID") == "" || window.sessionStorage.getItem("phaseID") == undefined)
        window.sessionStorage.setItem("phaseID", location.state.phaseID);
    const phaseID = window.sessionStorage.getItem("phaseID");

    const [formData, setFormData] = useState({});
    const [formAdditionalData, setFormAdditionalData] = useState({});
    const [data, setData] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [phaseData, setPhaseData] = useState({});

    useEffect(() => {
        if (preloadReady)
            initPhase();
    }, [preloadReady]);

    const initPhase = async () => {
        mainContext.setIsLoading(true);
        mainContext.setIsDirty(false);
        try {
            let response = await rest.post("getPhases", { phaseID: phaseID });
            setPhaseData(response[0]);
            mainContext.setIsLoading(false);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const initComboRateType = (id) => {
        let option = catalogs.parkingFeeType.find((item) => item.id == id);
        if (option) {
            if (id == 2) {
                setParkingPlaza(false);
            }
            return option;
        }
        return null;
    }

    useEffect(() => {
        debugger;
        if (Number.isInteger(data.rateType) && catalogs.parkingFeeType.length > 0) {
            let rateType = initComboRateType(data.rateType);
            if (rateType) {
                setData({
                    ...data,
                    rateType: rateType
                });
            }

        }
    }, [data, catalogs.parkingFeeType]);

    useEffect(() => {
        const initComercial = async () => {
            try {
                mainContext.setIsLoading(true);
                let response = await rest.postNew("getCommercialConditions", { phaseID: parseInt(phaseID) });
                await setData({ ...response });
                mainContext.setIsLoading(false);
                setTimeout(() => {
                    mainContext.setIsDirty(false);
                }, 500)
                mainContext.setIsDirty(false);
            }
            catch (error) {
                console.log(error);
            }
        }
        initComercial();
    }, [])

    const getFormDataFromChilren = (_formData) => {
        let newData = Object.assign({}, formData, _formData);
        setFormData(newData);
        mainContext.setIsDirty(true);
    }

    const getFormAdditionalDataFromChilren = (_formData) => {
        let newData = Object.assign({}, formAdditionalData, _formData);
        setFormAdditionalData(newData);
        mainContext.setIsDirty(true);
    }

    const sendForm = async () => {
        setIsSubmitted(true);
        if (await validateAdditionalsForm()) {
            mainContext.setIsLoading(true);
            let params2 = { ...formAdditionalData };
            params2.parkingSpacesPrice = (params2.parkingSpacesPrice == "") ? null : parseFloat(params2.parkingSpacesPrice);
            params2.minWarehouse = (params2.minWarehouse == "") ? null : parseFloat(params2.minWarehouse);
            params2.maxWarehouse = (params2.maxWarehouse == "") ? null : parseFloat(params2.maxWarehouse);
            params2.asignedParkingLots = (params2.asignedParkingLots == "") ? null : parseFloat(params2.asignedParkingLots);
            params2.priceM2Warehouse = (params2.priceM2Warehouse == "") ? null : parseFloat(params2.priceM2Warehouse);
            params2.parkingRelation = (params2.parkingRelation == "") ? null : parseFloat(params2.parkingRelation);
            params2.accessPrice = (params2.accessPrice == "") ? null : parseFloat(params2.accessPrice);
            params2.maxAccess = (params2.maxAccess == "") ? null : parseFloat(params2.maxAccess);
            params2.numParkingSpaces = (params2.numParkingSpaces == "") ? null : parseFloat(params2.numParkingSpaces);
            params2.rateType = (params2.rateType == "") ? null : params2.rateType;
            params2.priceParkingPlaza = (params2.priceParkingPlaza == "") ? null : parseFloat(params2.priceParkingPlaza);
            params2.paymentMethods = (params2.paymentMethods == "") ? [] : params2.paymentMethods;

            try {
                let response2 = await rest.postNew("savePhaseAdditionalSales", params2);
                mainContext.setIsLoading(false);
                mainContext.setIsDirty(false);
            }
            catch (error) { }
        }
        else {
            //scroll.scrollTo(180);
        }
    }

    const validateAdditionalsForm = async () => {
        let isValid = true;
        let requiredData = [];
        if (formAdditionalData.additionalParkingSpaces)
            requiredData.push("parkingSpacesPrice");

        if (formAdditionalData.warehouse)
            requiredData.push("priceM2Warehouse");

        if (formAdditionalData.poolParking)
            requiredData.push("accessPrice");

        requiredData.forEach((row) => {
            if (formAdditionalData[row] === undefined || formAdditionalData[row] === null || formAdditionalData[row] === "") {
                isValid = false;
            }
        })

        if (formAdditionalData.minWarehouse > formAdditionalData.maxWarehouse)
            isValid = false;

        return isValid;
    }

    return (
        <>
            <MenuSales phaseID={phaseID} phaseData={phaseData} linkActive={"conditions"} />
            <SalesSchemas phaseID={phaseID} />
            <SalesPromotions phaseID={phaseID} />
            <SalesAdditionals parkingPlaza={parkingPlaza} setParkingPlaza={setParkingPlaza} initData={data} handlerFormData={getFormAdditionalDataFromChilren} isSubmitted={isSubmitted} parkingPaymentMethods={catalogs.parkingPaymentMethods} parkingFeeType={catalogs.parkingFeeType} />
            <div className="row d-flex justify-content-end mt-5">
                <div className="col-10 d-flex justify-content-end">
                    {
                        (mainContext.isDirty) ?
                            <button onClick={() => { sendForm() }} className="btn btn-white w-25 mr-4">Guardar</button>
                            :
                            <button className="btn btn-white w-25 mr-4 disabled">Guardar</button>
                    }
                    <button className="btn btn-primary w-25 disabled">Continuar</button>
                </div>
            </div>
        </>
    )
}
export default Sales;