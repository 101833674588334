import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deleteImg from '../../../assets/icons/delete-files.svg';
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
import './styles.css'
import ImportIcon from '../../../assets/icons/icon-import.svg'
import Dropdown from "../Dropdown";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
import { Row } from 'react-bootstrap';
import { set } from 'lodash';
import InputCheckboxList from '../../atoms2/InputCheckboxList';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ModalCreateUser = (props) => {
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();
    const { showModal, setShow, setCancelAction, handlerAccept, cancel, success, edit, dataUser, nameCatalog, hasStatus, optionsM2ReferenceSubcategory } = props
    const [nameFile, setNameFile] = useState("Seleccionar archivo")
    const [stringFile, setStringFile] = useState("")
    const [rol, setRol] = useState("")
    const [formData, setFormData] = useState({ zones: [] });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [optionsCommercialCategories, setOptionsCommercialCategories] = useState(catalogs.commercialCategories);
    const [firstViewEdit, setFirstViewEdit] = useState(false);
    const [optionsZones, setOptionsZones] = useState([]);
    const [disbaleCheckboxes, setDisableCheckboxes] = useState(false);
    const [valuesM2References, setValuesM2References] = useState([]);
    const [optionsCity, setyOptionsCity] = useState([]);

    React.useEffect(() => {
        if (catalogs.cities) {
            let optionsC = [{ id: 0, name: "---" }];
            setyOptionsCity(optionsC.concat(catalogs.cities));
        }
    }, [catalogs.cities]);

    /**
        * Cierra el modal.
    */
    const handleClose = () => {
        setNameFile("Seleccionar archivo")
        setShow(false);
    }
    const sendData = async () => {
        let formatedData = {
            referenceM2GroupID: formData?.referenceM2GroupID,
            name: formData?.name,
            id_city: formData?.city?.id,
            status: formData?.status?.id,
            zones: formData?.zones,
            m2Reference: formData?.m2Reference
        }
        setIsSubmitted(true)
        if (valitation()) {
            await handlerAccept(formatedData)
            await setFormData({ zones: [] });
            setOptionsZones([]);
        }
    }
    const valitation = () => {
        if (!formData
            || formData.name === undefined
            || formData.name.trim() === ""
            || formData.name === null
            || formData.city === undefined
            || formData.city === null
            || formData.status === null
            || formData.status === undefined) {
            alert('Faltan datos por completar')
            return false
        }
        else
            return true
    }

    /**
        * Comfirmacion de borrado.
    */
    useEffect(() => {
        setIsSubmitted(false)
        setFormData({ zones: [] });
        setOptionsZones([]);
        setNameFile("Seleccionar archivo")

        if (edit) {
            setFirstViewEdit(true);
            setFormData({
                referenceM2GroupID: dataUser?.id,
                city: initComboCity("id", dataUser?.id_city),
                status: initComboStatus("id", dataUser?.status),
                name: dataUser.name,
                zones: dataUser.zones,
                m2Reference: initComboM2Reference(dataUser.m2reference)
            })
            cerateOptionsZones(formData.city);
        }

    }, [showModal, dataUser])

    const onFormDataChange = async (name, e) => {
        setIsSubmitted(false)
        setFormData({ ...formData, [name]: e.value });
    }
    const initComboCity = (filterBy1, filterBy2) => {
        if (catalogs.cities) {
            if (filterBy2 == 0)
                return { id: 0, name: "---" }

            let dato = catalogs.cities.filter((element) => { return element[filterBy1] == filterBy2 })[0];
            return dato;
        }
        else
            return null;
    }

    const initComboM2Reference = (data) => {
        let m2Reference = {};

        data.forEach((business) => {
            if (!m2Reference[business.commercial_business_id]) {
                m2Reference[business.commercial_business_id] = {};
            }

            (business.categories || []).forEach((category) => {
                (category.subcategories || []).forEach((subcategory) => {
                    m2Reference[business.commercial_business_id][subcategory.sub_commercial_categories_id] = {
                        m2_reference: subcategory.m2_reference
                    };
                });
            });
        });

        return m2Reference;
    };


    const initComboStatus = (filterBy1, filterBy2) => {
        const status = [{ name: 'Activo', id: 1 }, { name: 'Inactivo', id: 0 }];
        let dato = status.filter((element) => { return element[filterBy1] == (filterBy2 == true ? 1 : 0) })[0];
        return dato;
    }

    const cerateOptionsZones = async (city) => {
        try {
            const zones = catalogs.states;

            // Filtrar las zonas donde al menos uno de los objetos en "cities" tiene el mismo "city_id" que el "city.id"
            const filteredZones = zones.filter(zone =>
                zone.cities.some(cityObj => cityObj.city_id === city.id)
            );

            // Formatear las opciones de zonas si es necesario
            const optionStates = filteredZones.map(zone => ({
                id: zone.id,
                name: zone.name
            }));

            return setOptionsZones(optionStates);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }


    React.useEffect(() => {
        if (!firstViewEdit) {
            setOptionsZones([]);
            if (formData.city) {
                setFormData({ ...formData, zones: [] });
                cerateOptionsZones(formData.city);
            }
        } else {
            setFirstViewEdit(false);
        }
    }, [formData.city]);


    const handleCheckboxList = async (paramName, data) => {
        setFormData({ ...formData, zones: data });
    }

    const onFormDataChangeM2Reference = (commercial_business_id, sub_category_id, e) => {
        mainContext.setIsDirty(true);

        const value = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(value) && value >= 0 && value <= 100000) {
            setFormData((prevFormData) => {
                const updatedFormData = { ...prevFormData };

                if (!updatedFormData.m2Reference) {
                    updatedFormData.m2Reference = {};
                }

                if (!updatedFormData.m2Reference[commercial_business_id]) {
                    updatedFormData.m2Reference[commercial_business_id] = {};
                }

                if (!updatedFormData.m2Reference[commercial_business_id][sub_category_id]) {
                    updatedFormData.m2Reference[commercial_business_id][sub_category_id] = {};
                }

                updatedFormData.m2Reference[commercial_business_id][sub_category_id].m2_reference = value;

                return updatedFormData;
            });
        }
    };

    // React.useEffect(() => {
    //     console.log('formData: ', formData);
    // }, [formData]);

    return (
        //TODO: Fix styles
        <section>
            <Modal size={"lg"} show={showModal} onHide={() => { handleClose() }} backdrop="static" centered >
                <Modal.Header className="no-border p-1" closeButton>
                    <Modal.Title className="pl-3"></Modal.Title>
                </Modal.Header>
                <Modal.Body className={"text-center"} >
                    <div className="custom-card">
                        <div className="custom-card-header px-3 d-flex">
                            <h4 className="py-3">INFORMACIÓN PRINCIPAL</h4>
                            <div className=" py-2 d-flex justify-content-end">
                            </div>
                        </div>
                        <div className="custom-card-body px-3">
                            <div className="row">
                                <div className="form-group with-message col-4">
                                    <label className="input-label form-label">
                                        * Nombre del grupo
                                    </label>
                                    <div className="">
                                        <input type="text" value={formData.name} onChange={(e) => { onFormDataChange("name", e.target) }} maxLength="300" className="form-control black-border large-input" />
                                        {
                                            (isSubmitted && !formData.name) &&
                                            <p className="error">*Campo obligatorio.</p>
                                        }
                                        {
                                            (isSubmitted && formData.name && formData.name.trim() == "") &&
                                            <p className="error">*No se permiten solo espacios.</p>

                                        }
                                    </div>
                                </div>
                                <div className="col-4  form-group with-message">
                                    <label className="input-label form-label">
                                        * Ciudad
                                    </label>
                                    <div className="">
                                        <Dropdown value={formData.city} options={optionsCity} onChange={(e) => { onFormDataChange("city", e) }} optionValue="id" optionLabel="name" className="w-100" />
                                        {
                                            (isSubmitted && !formData.city) &&
                                            <p className="error">*Campo obligatorio.</p>
                                        }
                                    </div>
                                </div>
                                <div className="col-4  form-group with-message">
                                    <label className="input-label form-label">
                                        * Estatus
                                    </label>
                                    <div className="">
                                        <Dropdown value={formData.status} options={[{ name: 'Activo', id: 1 }, { name: 'Inactivo', id: 0 }]} onChange={(e) => { onFormDataChange("status", e) }} optionValue="id" optionLabel="name" className="w-100" />
                                        {
                                            (isSubmitted && !formData.status) &&
                                            <p className="error">*Campo obligatorio.</p>
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="custom-card mt-4">
                        <div className="custom-card-header px-3 d-flex">
                            <h4 className="py-3">SELECCIONAR ZONAS (OPCIONAL)</h4>
                        </div>
                        <div className="custom-card-body px-3">
                            <Row>
                                <div className="col">
                                    <div className="form-group with-message">
                                        <InputCheckboxList
                                            handleData={handleCheckboxList}
                                            catalog={optionsZones}
                                            currentData={formData.zones}
                                            idProperty="id"
                                            displayName="name"
                                            idx="id"
                                            cssClass="features-checkList row"
                                            columns={5}
                                            renderColumns={true}
                                            disableCheckBoxes={disbaleCheckboxes}
                                        />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>

                    <div className="custom-card mt-4">
                        <div className="custom-card-header px-3 d-flex">
                            <h4 className="py-3">{edit ? "EDITAR" : "AGREGAR"} M2 DE REFERENCIA POR SUBCATEGOIRÍA</h4>
                        </div>


                        <div className="col" style={{ marginBottom: '2em' }}>
                            <table className="w-100 table-no-border table-striped" style={{ borderCollapse: "collapse", tableLayout: "fixed", marginTop: '1.3em' }}>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ width: "30%" }}>Categoría</th>
                                        <th className="text-center" style={{ width: "40%" }}>Subcategoría</th>
                                        <th className="text-center" style={{ width: "30%" }}>M2 de Referencia</th>
                                    </tr>
                                </thead>
                            </table>

                            <div style={{ maxHeight: "30em", overflowY: "auto" }}>
                                <table className="w-100 table-no-border table-striped" style={{ borderCollapse: "collapse", tableLayout: "fixed" }}>
                                    <tbody>
                                        {optionsM2ReferenceSubcategory.length > 0 && optionsM2ReferenceSubcategory.map((commercialBusiness, indexCommercialBusiness) => (
                                            <React.Fragment key={`business-${indexCommercialBusiness}`}>
                                                <tr className="table-row-border-top text-center">
                                                    <td style={{ backgroundColor: 'white' }}><b>{commercialBusiness.name}</b></td>
                                                </tr>
                                                {commercialBusiness.data.map((category, indexCategory) => (
                                                    <tr key={`category-${category.sub_category_id}`}>
                                                        <td className="text-center" style={{ width: "30%" }}>
                                                            {category.name_category}
                                                        </td>
                                                        <td className="text-center" style={{ width: "40%" }}>
                                                            {category.name_sub_category}
                                                        </td>
                                                        <td className="text-center" style={{ width: "30%" }}>
                                                            <input
                                                                value={formData?.m2Reference?.[commercialBusiness.commercial_business_id]?.[category.sub_category_id]?.m2_reference || ""}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*\.?\d{0,2}$/.test(value) && value >= 0 && value <= 100000) {
                                                                        onFormDataChangeM2Reference(commercialBusiness.commercial_business_id, category.sub_category_id, e);
                                                                    }
                                                                }}
                                                                className="form-control"
                                                                style={{ width: "60%" }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div
                        className="container d-flex justify-content-end mt-5">
                        <button className="btn btn-white " onClick={() => { handleClose() }}>Cancelar</button>

                        <button className="btn btn-primary ml-3" onClick={() => { sendData() }}>{edit ? "Actualizar" : "Agregar"}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}
export default ModalCreateUser