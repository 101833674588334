
const axios = require('axios');
class RestActions 
{
    async getURL(url) 
    {
      try {
        const response = await axios.get(url);
        return response;
      } 
      catch (error) 
      {
        throw error;
      }
    }

    async get(action) 
    {
      let url = `${process.env.REACT_APP_SERVER_URL}`;
      try {
        const response = await axios.get(`${url}/api/${action}`);
        return response.data.data;
      } 
      catch (error) 
      {
        throw error;
      }
    }

    async postNew(action, params) 
    {
      let url = `${process.env.REACT_APP_NEW_STACK_SERVER_URL}`;
      console.log('new POST: ', `${url}/api/${action}`);
      
      try {
        const token = await this.post(`getToken`);
        const response = await axios.post(`${url}/api/${action}`, {...params, token});
        return response.data.data;
      } 
      catch (error) 
      {
        //throw error;
        throw error.response.data.error;
      }
    }

    async post(action, params) 
    {
      let url = `${process.env.REACT_APP_SERVER_URL}`;

      try {
        const response = await axios.post(`${url}/api/${action}`, params);
        return response.data.data;
      } 
      catch (error) 
      {
        //throw error;
        throw error.response.data.error;
      }
    }

  async logout() 
  {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/logout`,
        data: this.data
      })
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default RestActions
